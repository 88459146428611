import {
  GetShiftPlanningShiftsResponse,
  PartnerClaimantsQueryResponse,
  PartnerWorkerInfiniteQueryResponse,
  PlannerShiftResponse,
  ShiftPlanningParams,
} from './partner-planner-service.types';
import { PartnerApiService } from './partner-api-service';

class PartnerPlannerService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public async getShiftPlanningShifts({
    fromDate,
    company,
    branchOffice,
    department,
    pageParam,
    pageSizeParam,
    jobType,
    filledStatus,
  }: ShiftPlanningParams) {
    const response = await this.partnerApiService.get<GetShiftPlanningShiftsResponse>({
      params: {
        page: pageParam,
        pageSize: pageSizeParam,
        fromDate: fromDate?.toISO(),
        companyId: company,
        branchOfficeId: branchOffice,
        departmentId: department,
        jobType: jobType,
        filledStatus,
      },
      path: 'shift-planning/shifts',
    });

    return response;
  }

  public async getShift(shiftId: string): Promise<PlannerShiftResponse> {
    return this.partnerApiService.get<PlannerShiftResponse>({ path: `planning/${shiftId}` });
  }

  public async getShiftClaimants(
    shiftId: string,
    page: number
  ): Promise<PartnerClaimantsQueryResponse> {
    return this.partnerApiService.get<PartnerClaimantsQueryResponse>({
      path: `planning/${shiftId}/claimants`,
      params: {
        page,
        pageSize: 25,
      },
    });
  }

  public async getShiftWorkers(
    shiftId: string,
    fullNameSearch: string | undefined,
    page: number,
    pageSize: number
  ): Promise<PartnerWorkerInfiniteQueryResponse> {
    return this.partnerApiService.get<PartnerWorkerInfiniteQueryResponse>({
      path: `planning/${shiftId}/workers`,
      params: {
        page,
        pageSize,
        fullNameSearch,
      },
    });
  }

  public async claimShift(workerId: string, shiftId: string): Promise<void> {
    return this.partnerApiService.update({ path: `planning`, body: { workerId, shiftId } });
  }

  public async cancelShift(shiftClaimId: string): Promise<void> {
    return this.partnerApiService.update({ path: `planning/cancel/${shiftClaimId}` });
  }
}

export { PartnerPlannerService };
