import { DateTime } from 'luxon';
import { AutocompleteOption } from 'redesign';
import { getJobTypes } from '../../../shifts/filters/utils/utils';
import {
  ProjectPlanningFiltersData,
  ProjectPlanningFiltersFormType,
} from '../project-planning-filters.types';
import { ProjectPlanningPageParams } from '../../table/hooks/use-project-planning.types';

const getProjectPlanningFiltersDefaultValues = (
  appliedFilters: ProjectPlanningPageParams,
  filterData: ProjectPlanningFiltersData
): ProjectPlanningFiltersFormType => {
  const toDate = appliedFilters.toDate
    ? appliedFilters.toDate.toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd');
  const result: ProjectPlanningFiltersFormType = {
    toDate,
    company: null,
    branchOffice: null,
    department: null,
    jobType: null,
    isFreelance: appliedFilters.isFreelance ?? false,
    isTempWork: appliedFilters.isTempWork ?? false,
    filledStatusCheckboxValue: !!appliedFilters.filledStatus,
    allowApplicantsFilter: appliedFilters.hasApplicants != null ? true : false,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    filledStatus: appliedFilters.filledStatus ? appliedFilters.filledStatus : 'inProgress',
    hasApplicants: appliedFilters.hasApplicants != null ? appliedFilters.hasApplicants : true,
    businessUnitCheckboxValue: !!appliedFilters.company,
    toDateCheckboxValue: !!appliedFilters.toDate,
    employmentTypeCheckboxValue: !!appliedFilters.isFreelance || !!appliedFilters.isTempWork,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters.jobType, filterData.jobTypes);
    if (jobTypes?.length) {
      result.jobType = jobTypes.map((jt) => ({ id: jt.value, name: jt.label }));
    }
  }

  if (!appliedFilters.company) {
    return result;
  }
  if (!filterData?.companies) {
    return result;
  }
  const companyFilter = filterData.companies.find((c) => c.companyId === appliedFilters.company);

  if (!companyFilter) {
    return result;
  }

  const company: AutocompleteOption = {
    value: companyFilter.companyId,
    label: companyFilter.companyName,
  };
  result.company = company;

  if (!appliedFilters.branchOffice) {
    return result;
  }

  const branchOfficeFilter = companyFilter.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOffice
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.department) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.department
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};
export { getProjectPlanningFiltersDefaultValues };
