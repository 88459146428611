import { LevelTableCellProps } from 'redesign';
import { DateTime } from 'luxon';

export const INITIAL_PAGE = 0;

type GetPartnerPlacementsWithParsedInfo = {
  workerName: string;
  placementId: string;
  jobName: string;
  jobType: string;
  companyBranchOfficeInfo: {
    companyName: string;
    branchOfficeName: string;
  };
  departmentName: string;
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};

export type PartnerPlacementsColumnDefinition = GetPartnerPlacementsWithParsedInfo;
export type PartnerPlacementsCellProp<T extends keyof PartnerPlacementsColumnDefinition> =
  LevelTableCellProps<
    PartnerPlacementsColumnDefinition,
    PartnerPlacementsColumnDefinition[T],
    PartnerPlacementsTableOptions
  >;

export type PartnerPlacementsTableOptions = {
  getRowActions: (placementId: string) => React.ReactNode[];
};
