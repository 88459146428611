import { useNavigate, useParams } from 'react-router-dom';
import { shiftAlerts, useAlert } from 'shared/components/alerts';

import { Box, Grid, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { useCallback, useMemo } from 'react';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { GetCompaniesResponse } from 'hq/services/company-service.types';

import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useShiftPlanningPage } from 'hq/components/views/planner/shifts/table/hooks/use-shift-planning';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { transformData } from '../../utils/transform-data';
import { useHqGetShift } from '../../hooks/hq/use-hq-get-shift';
import { useHqUpdateShift } from '../../hooks/hq/use-hq-update-shift';
import { HqShiftForm } from '../hq-shift-form';
import { CompanyInput } from '../../components/forms/shared/inputs/company-input';
import { useHqGeneratePlannerPath } from '../hq-generate-shift-form-path';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';
import { stringifyFilters } from '../../../../utils/stringify-filters';
import { getDefaultValuesForShiftForm } from '../../utils/get-default-values-for-shift-form';
import { HQShiftFormData } from '../types/hq-shift-form.types';

type ShiftEditProps = {
  shift: ShiftDetailResponse;
  companies: GetCompaniesResponse[];
  shiftId: string;
  workDetails: DetailsForWorkTemplate;
};

export const HqShiftEditDataProvider = () => {
  const { id: shiftId } = useParams<'id'>();
  invariant(shiftId, 'Shift ID was supposed to be passed via route param');

  const shiftQuery = useHqGetShift(shiftId);
  const companiesQuery = useGetCompanies();
  const workDetailsQuery = useGetWorkDetails(shiftId);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  return (
    <ShiftEdit
      shift={shiftQuery.data}
      shiftId={shiftId}
      companies={companiesQuery.data}
      workDetails={workDetailsQuery.data}
    />
  );
};

const ShiftEdit = ({ shift, shiftId, companies, workDetails }: ShiftEditProps) => {
  const { alertSuccess, alertError } = useAlert();
  const { filters: appliedFilters } = useShiftPlanningPage();
  const mergedFilters = useMemo(
    () => ({ ...stringifyFilters(appliedFilters), shiftDetailId: shiftId }),
    [appliedFilters, shiftId]
  );
  const navigate = useNavigate();
  const generatePlannerPath = useHqGeneratePlannerPath();
  const disableContractFields = shift.claimants.length > 0;
  const mutation = useHqUpdateShift({
    onSuccess: () => {
      alertSuccess(shiftAlerts.success.created);
      navigate(generatePlannerPath(mergedFilters));
    },
    onError: (e) => alertError(e),
  });

  const onCancel = useCallback(() => {
    navigate(generatePlannerPath(mergedFilters));
  }, [navigate, generatePlannerPath, mergedFilters]);

  const formMethods = useForm<HQShiftFormData>({
    defaultValues: { ...getDefaultValuesForShiftForm(shift), jobId: undefined },
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate({ ...data, id: shiftId });
    })();
  }, [formMethods, mutation, shiftId]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Shift bewerken" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          {disableContractFields ? (
            <Typography mt={2}>
              Contract-gerelateerde velden kunnen niet aangepast worden, omdat er claims op deze
              shift staan.
            </Typography>
          ) : null}
          <Grid container>
            <Grid item xs={12}>
              <CompanyInput
                options={companies.map((i) => ({ label: i.name, value: i.id }))}
                disabled={true}
              ></CompanyInput>
              <HqShiftForm mode="update" workDetails={workDetails} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
