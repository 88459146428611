import { useMutation } from 'react-query';
import { projectPlanningService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { HQProjectPayloadEdit } from 'hq/services/project-planning-service.types';

export const useEditProject = () => {
  const mutationFn = async ({
    projectId,
    projectData,
  }: {
    projectId: string;
    projectData: HQProjectPayloadEdit;
  }) => await projectPlanningService.editProject(projectId, projectData);
  const invalidateQuery = useInvalidateQuery();
  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.projects());
    },
  });
};
