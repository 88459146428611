import { ChangeEvent } from 'react';
import { LwButton } from 'redesign';
import { IconAdd } from 'assets/img';
import { styled } from '@mui/material';

type FileUploadSectionProps = {
  disableUplad: boolean;
  onFilesChange: (files: File[]) => void;
};

export const FileUpload = ({ disableUplad, onFilesChange }: FileUploadSectionProps) => {
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      onFilesChange(uploadedFiles);
    }
  };

  return (
    <LwButton
      color="secondary"
      startIcon={<IconAdd />}
      tabIndex={-1}
      component="label"
      type="submit"
      data-testid="add-accreditation-form-file-select"
      style={{ marginTop: 12, marginBottom: 12 }}
      disabled={disableUplad}
    >
      Selecteer bestanden
      <VisuallyHiddenInput type="file" onChange={handleFileUpload} multiple />
    </LwButton>
  );
};

const VisuallyHiddenInput = styled('input')({
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
