import { Box, Divider, Grid, styled } from '@mui/material';
import { WorkFormDetails } from 'shared/components/work-forms/details/work-form-details';
import {
  ProjectFormInputsProps,
  useGetProjectFormInputs,
} from '../hooks/use-get-project-form-inputs';

export const ProjectForm = (options: ProjectFormInputsProps) => {
  const { workDetails } = options;
  const formInputs = useGetProjectFormInputs(options);

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" height="100%">
          <form aria-label="Projects form">
            <Grid container item spacing={4} xs={12}>
              {...formInputs.map((element, i) => (
                <Grid key={i} item xs={6}>
                  {element}
                </Grid>
              ))}
            </Grid>
          </form>
          <StyledDivider />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <WorkFormDetails workDetails={workDetails} />
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
