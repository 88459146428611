import React, { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import {
  BlockLink,
  createBlock,
  LwBlock,
  LwBlockGridContainer,
  LwButton,
  Page,
  SearchBar,
  Toolbox,
  useOnScrollDown,
} from 'redesign';
import { IconAdd, IconFlexPoolOnlyJob, IconJobs } from 'assets/img';
import { useGetJobsListing } from 'ogp/hooks/queryHooks/jobs/use-get-job-blocks';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'Routes';
import { useExternalParams } from 'shared/hooks';
import { UseInfiniteQueryResult } from 'react-query';
import { GetFilteredJobsListingResponse } from 'ogp/services/job-service.types';
import { OGPJobsListingExternalParams, OGPJobsListingSetParams } from './jobs.types';
import { DepartmentSelect } from '../components';

export const JOBS_INITIAL_PARAMS = {
  departmentId: {
    initialValue: undefined,
    type: 'string',
  },
  search: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export const JobsDataProvider = () => {
  const [params, setParams] = useExternalParams(JOBS_INITIAL_PARAMS);
  const query = useGetJobsListing(params);

  return <Jobs params={params} setParams={setParams} query={query} />;
};

const Jobs = ({
  params,
  setParams,
  query,
}: {
  params: OGPJobsListingExternalParams;
  setParams: OGPJobsListingSetParams;
  query: UseInfiniteQueryResult<GetFilteredJobsListingResponse>;
}) => {
  const flatData = useMemo(
    () => query.data?.pages.flatMap((data) => data.items).map((block) => createBlock(block)) ?? [],
    [query.data]
  );
  const generatePath = useCompanyRelativePath();
  const { fetchIfBottomScrolled } = useOnScrollDown({ infiniteQueryResult: query, flatData });

  const routeParams = useMemo(() => Object.fromEntries(Object.entries(params)), [params]);
  const onChange = useCallback(
    (search: string) => {
      setParams({ search });
    },
    [setParams]
  );

  return (
    <Page
      header={
        <HeaderStatistics
          titleText="Functies"
          subheaderText="Hier zie je alle functies binnen jouw vestiging"
          type="jobs"
        />
      }
      toolbox={
        <Toolbox>
          <LwButton
            to={generatePath(routes.JobsCreate, undefined, routeParams)}
            startIcon={<IconAdd />}
            color="primary"
            iconColorMode="fill"
          >
            Voeg functie toe
          </LwButton>
          <SearchBar
            onChange={onChange}
            placeholder="Filter functies op naam"
            debounceTime={300}
            initialValue={params.search}
          />
          <Toolbox.PushRight>
            <DepartmentSelect />
          </Toolbox.PushRight>
        </Toolbox>
      }
      onContentScroll={(e) => {
        fetchIfBottomScrolled(e.target as HTMLDivElement);
      }}
    >
      {flatData.length === 0 ? (
        <Typography>Er zijn geen functies die aan je filter voldoen.</Typography>
      ) : (
        <LwBlockGridContainer>
          {flatData.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id}>
              <BlockLink to={generatePath(routes.JobsDetail, { id: block.id }, routeParams)}>
                <LwBlock
                  icon={
                    block.type?.value === 'flex-pool-only-job' ? (
                      <IconFlexPoolOnlyJob {...iconProps} />
                    ) : (
                      <IconJobs {...iconProps} />
                    )
                  }
                  severity={block.type?.severity}
                  id={block.id}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                />
              </BlockLink>
            </Grid>
          ))}
        </LwBlockGridContainer>
      )}
    </Page>
  );
};

const iconProps: Pick<React.CSSProperties, 'width' | 'height'> = {
  width: 24,
  height: 24,
};
