import { Tooltip, Typography } from '@mui/material';

export const FlexpoolsDetail = (props: { flexPools?: string[] }) => {
  const { flexPools } = props;
  if (!flexPools || flexPools.length === 0) {
    return null;
  }
  const label = flexPools.length === 1 ? 'flexpool' : 'flexpools';
  return (
    <Tooltip title={flexPools.length > 0 ? flexPools.join(', ') : null}>
      <Typography noWrap variant="subtitle2">
        {flexPools.length + ' ' + label}
      </Typography>
    </Tooltip>
  );
};
