import { Box, ClickAwayListener, styled } from '@mui/material';
import { IconCross, IconSearch } from 'assets/img';
import { useForm } from 'react-hook-form';
import { LwFormInput, LwIconButton, themeConfig } from 'redesign';
import { useDebounce } from 'shared/hooks/use-debounce';
import { useEffect } from 'react';
import { Props } from './search-bar.types';
import { useSearchBar } from './use-search-bar';

const SearchBar = ({ initialValue = '', onChange, placeholder, debounceTime = 0 }: Props) => {
  const { control, reset, watch } = useForm({
    defaultValues: {
      search: initialValue,
    },
  });
  const { folded, toggle } = useSearchBar();
  const search = watch('search');
  const value = useDebounce(search, debounceTime);

  useEffect(() => {
    onChange(value);
  }, [value]);

  if (folded) {
    return (
      <LwIconButton
        onClick={toggle}
        iconColorMode="stroke"
        color="secondary"
        aria-label="Open text search"
      >
        <Box
          component="span"
          display="flex"
          width={themeConfig.icons.width}
          height={themeConfig.icons.height}
        >
          <IconSearch />
        </Box>
      </LwIconButton>
    );
  }

  return (
    <ClickAwayListener onClickAway={toggle}>
      <ConstrainedBox>
        <LwFormInput
          autoFocus
          InputProps={{
            startAdornment: (
              <Adornment>
                <StyledIconSearch />
              </Adornment>
            ),
            endAdornment: (
              <Adornment
                onClick={() => reset()}
                role="button"
                style={{ cursor: 'pointer' }}
                aria-label="Clear text search"
              >
                <StyledIconCross />
              </Adornment>
            ),
          }}
          placeholder={placeholder}
          label=""
          name="search"
          control={control}
        />
      </ConstrainedBox>
    </ClickAwayListener>
  );
};

const Adornment = styled('span')(({ theme }) => ({
  height: themeConfig.icons.height,
  margin: theme.spacing(0, 4),
  width: themeConfig.icons.width,
}));

const ConstrainedBox = styled(Box)({
  maxHeight: '56px',
  '& *': {
    maxHeight: '56px',
  },
});

const StyledIconSearch = styled(IconSearch)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwSecondary[60]}`,
  },
}));

const StyledIconCross = styled(IconCross)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwSecondary[60]}`,
  },
}));

export { SearchBar };
