import { Control } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';
import { HelperTexts } from 'shared/components/shift-forms/components/forms/shared/types';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const HourlyRateElement = ({
  control,
  disabled,
  existingRate,
  helperTexts,
  employmentTypes,
}: {
  control: Control<ProjectFormTypeAll>;
  disabled: boolean;
  existingRate: number | undefined;
  helperTexts: HelperTexts;
  employmentTypes: {
    isTemp: boolean;
    isFreelance: boolean;
  };
}) => {
  const tempLabel = 'Bruto Uurloon';
  const freelanceLabel = 'Uurtarief';
  const bothLabel = 'Uurtarief / Bruto Uurloon';

  const tempRequiredMessage = 'Voer bruto uurloon in';
  const freelanceRequiredMessage = 'Voer uurtarief in';
  const bothRequiredMessage = 'Voer uurtarief of bruto uurloon in';

  const label = employmentTypes.isTemp
    ? employmentTypes.isFreelance
      ? bothLabel
      : tempLabel
    : freelanceLabel;

  const required = employmentTypes.isTemp
    ? employmentTypes.isFreelance
      ? bothRequiredMessage
      : tempRequiredMessage
    : freelanceRequiredMessage;

  return (
    <LwFormNumberInput
      name="hourlyRate"
      label={label}
      control={control}
      rules={{ required }}
      step={0.01}
      min={0}
      placeholder={`${(existingRate || 0) / 100}`}
      helperText={helperTexts.hourlyRate}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};
