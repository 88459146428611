import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { adminSettingsService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';

export const useEditMinimumJobTypeRate = () => {
  const mutationFn = async ({
    jobTypeId,
    minimumRateCents,
  }: {
    jobTypeId: string;
    minimumRateCents: number;
  }) => await adminSettingsService.editMinimumJobTypeRate(jobTypeId, minimumRateCents);

  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.minimumRateForJobTypes());
    },
  });
};
