import { Control } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const JobFormElement = ({
  control,
  options,
  disabled,
}: {
  control: Control<ProjectFormTypeAll>;
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  return (
    <LwFormSelect
      name="jobId"
      label="Functie"
      defaultLabel="Selecteer een functie..."
      options={options}
      disabled={disabled}
      control={control}
      data-testid="job-select"
      rules={{ required: 'Selecteer een functie' }}
    />
  );
};
