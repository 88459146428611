import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { LwFormAutocomplete } from 'redesign';
import { useState } from 'react';
import { RenderUnsettledUI } from 'shared/components';
import { useDebounce } from 'shared/hooks/use-debounce';
import { useGetAvailableFlexWorkersForFlexPool } from '../hooks/use-get-available-flex-workers-for-flex-pool';

const FlexPoolAddFlexWorker = ({ flexPoolId }: { flexPoolId: string }) => {
  const { control, setValue } = useFormContext<{
    flexWorker: { id: string; email: string };
  }>();

  const [input, setSelected] = useState<string>('');
  const debouncedInput = useDebounce(input, 300);

  const { data, isFetching, status, refetch, error } = useGetAvailableFlexWorkersForFlexPool(
    flexPoolId,
    debouncedInput
  );

  if (status === 'error') {
    return <RenderUnsettledUI data={{ status, error, refetch }} />;
  }

  return (
    <Box>
      <LwFormAutocomplete
        name="flexWorker"
        label="Flexwerker"
        getOptionLabel={(option) => option?.email ?? ''}
        getOptionKey={(option) => option.id ?? ''}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id;
        }}
        options={data ?? []}
        isLoading={isFetching}
        control={control}
        rules={{ required: true }}
        onChange={(_event, value) => {
          if (value) {
            setValue('flexWorker', value);
          }
        }}
        onInputChange={(_e, value) => {
          setSelected(value);
        }}
      />
    </Box>
  );
};

export { FlexPoolAddFlexWorker };
