import { ContextMenuItem, Table, useOnScrollDown } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import {
  PartnerPlacementsColumnDefinition,
  PartnerPlacementsTableOptions,
} from './partner-placements.types';
import { useGetPartnerPlacements } from './hooks/use-get-partner-placements';
import { getPartnerPlacementsTableData } from './utils/get-partner-placements-table-data';
import { useCancelShiftPlacement } from './hooks/use-cancel-shift-placement';
import { getPartnerPlacementsColumnDefinition } from './utils/get-partner-placements-column-definition';
import { PlacementsFiltersDataProvider } from './filters/placements-filters-data-provider';
import { PlacementsFilters } from './hooks/use-placements-page.types';
import { usePlacementsPage } from './hooks/use-placements-page';
import { GetPartnerPlacementsResponse } from '../../../shared/services/partner-placements-service.types';
import { partnerPlacementAlerts, useAlert } from '../../../../shared/components/alerts';

type Props = {
  onSelectionChange: (selectedIds: string[]) => void;
  appliedFilters: PlacementsFilters;
  setFilters: ReturnType<typeof usePlacementsPage>['setFilters'];
  filtersOpen: boolean;
  toggleFilters: () => void;
};

export const PartnerPlacementsDataProvider = (props: Props) => {
  const placementsQuery = useGetPartnerPlacements(props.appliedFilters);

  if (placementsQuery.status !== 'success') {
    return <RenderUnsettledUI data={placementsQuery} />;
  }

  return <PartnerPlacementsTable infiniteQueryResult={placementsQuery} {...props} />;
};
const PartnerPlacementsTable = ({
  onSelectionChange,
  infiniteQueryResult,
  appliedFilters,
  setFilters,
  filtersOpen,
  toggleFilters,
}: Props & {
  infiniteQueryResult: InfiniteQueryObserverSuccessResult<GetPartnerPlacementsResponse, unknown>;
}) => {
  const flatData = useMemo(
    () => infiniteQueryResult.data.pages.flatMap((page) => page.items),
    [infiniteQueryResult.data]
  );
  const tableData = useMemo(() => getPartnerPlacementsTableData(flatData), [flatData]);
  const columns = useMemo(() => getPartnerPlacementsColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult,
    flatData,
  });
  const { alertSuccess, alertError } = useAlert();

  const { mutate } = useCancelShiftPlacement();

  const handleSelectionChange = useCallback(
    (selection: PartnerPlacementsColumnDefinition[]) => {
      onSelectionChange(selection.map((i) => i.placementId));
    },
    [onSelectionChange]
  );

  const getRowActions = (shiftClaimId: string) => [
    <ContextMenuItem
      onClick={() =>
        mutate(
          { shiftClaimId },
          {
            onSuccess: () => alertSuccess(partnerPlacementAlerts.success.deleted),
            onError: (e) => alertError(e),
          }
        )
      }
      key={`${shiftClaimId}_cancel_placement`}
    >
      Annuleer plaatsing
    </ContextMenuItem>,
  ];

  return (
    <>
      <Box maxHeight={'100%'}>
        <Table<PartnerPlacementsColumnDefinition, PartnerPlacementsTableOptions>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          onScroll={fetchIfBottomScrolled}
          onSelectionChange={handleSelectionChange}
          selectAllMode="VISIBLE"
          selectable={true}
          getRowActions={getRowActions}
        />
      </Box>
      <PlacementsFiltersDataProvider
        appliedFilters={appliedFilters}
        handleFiltersChange={setFilters}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />
    </>
  );
};
