import {
  BusCSVUploadPayload,
  BusCSVUploadResponse,
  MinimumRateForJobTypesResponse,
  PlatformSettingsResponse,
  UpdatePlatformSettingsPayload,
} from './admin-settings-service.types';
import { HqApiService } from './hqApiService';
import { FeatureFlags } from '../../shared/services/mappers/mappers';

class AdminSettingsService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getPlatformSettings = async () => {
    return await this.hqApiService.get<PlatformSettingsResponse>({
      path: 'admin-settings/platform-settings',
    });
  };

  public getMinimumRateForJobTypes = async () => {
    return await this.hqApiService.get<MinimumRateForJobTypesResponse>({
      path: 'admin-settings/minimum-rate-for-job-types',
    });
  };

  public updatePlatformSettings = async (platformSettings: UpdatePlatformSettingsPayload) => {
    await this.hqApiService.update<void>({
      path: 'admin-settings/platform-settings',
      body: platformSettings,
    });
  };

  public editMinimumJobTypeRate = async (jobType: string, minimumRateCents: number) => {
    await this.hqApiService.update<void>({
      path: `admin-settings/minimum-rate-for-job-types/${jobType}`,
      body: { minimumRateCents },
    });
  };

  public uploadBusCsv = async (uploadBusCsvInfo: BusCSVUploadPayload) => {
    const formData = new FormData();
    formData.append('file', uploadBusCsvInfo.file);
    return await this.hqApiService.create<BusCSVUploadResponse>({
      path: 'admin-settings/process-csv',
      body: formData,
    });
  };

  public updateFeatureFlags = async (featureFlags: Partial<FeatureFlags>) => {
    await this.hqApiService.update({
      path: `admin-settings/update-feature-flags`,
      body: { featureFlags },
    });
  };
}

export { AdminSettingsService };
