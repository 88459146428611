import { Grid } from '@mui/material';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { HQProjectFormTypeDuplicate } from 'shared/components/project-forms/types/project-form.types';
import { CompanyInput } from 'shared/components/shift-forms/components/forms/shared/inputs/company-input';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { HQProjectPayloadDuplicate } from 'hq/services/project-planning-service.types';
import { InputSelectOption } from '@types';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { ProjectDuplicateFormProvider } from './project-duplicate-form-provider';
import { useProjectPlanningPage } from '../../table/hooks/use-project-planning';
import { useDuplicateProject } from '../../hooks/use-duplicate-project';

type ProjectDuplicateProps = {
  ancestorProjectId: string;
  departments: InputSelectOption[];
  companies: GetCompaniesResponse[];
  defaultValues?: Partial<HQProjectFormTypeDuplicate>;
  successMessage: string;
  pageTitle: string;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectDuplicateFormPage = ({
  companies,
  defaultValues = {},
  successMessage,
  pageTitle,
  ancestorProjectId,
  workDetails,
}: ProjectDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();

  const formMethods = useForm<HQProjectFormTypeDuplicate>({
    defaultValues,
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const mutation = useDuplicateProject();
  const { params: filters } = useProjectPlanningPage();

  const companyId = formMethods.watch('companyId');

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data: HQProjectFormTypeDuplicate) => {
      const payload: HQProjectPayloadDuplicate = {
        ancestorProjectId: ancestorProjectId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: data.weeklyHours,
        hourlyRateCents: Math.round(+data.hourlyRate * 100),
      };
      mutation.mutate(payload, {
        onSuccess: (id) => {
          alertSuccess(successMessage);
          navigate(
            generatePath(
              hqRoutes.PlannerProject,
              {},
              { ...stringifyFilters(filters), projectDetailId: id }
            )
          );
        },
        onError: (e) => alertError(e),
      });
    })();
  }, []);

  const onCancel = useCallback(
    () => navigate(generatePath(hqRoutes.PlannerProject, stringifyFilters(filters))),
    [filters, generatePath, navigate]
  );

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={true}
            ></CompanyInput>
            {companyId ? (
              <ProjectDuplicateFormProvider companyId={companyId} workDetails={workDetails} />
            ) : null}
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
