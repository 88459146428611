export const shiftAlerts = {
  success: {
    created: 'Shift succesvol aangemaakt',
    updated: 'Shift succesvol aangepast',
    deleted: 'Shift succesvol verwijderd',
    multiplied: 'Shift succesvol gedupliceerd',
    unsubscribed: 'Flexwerkers succesvol van shift afgemeld',
    subscribed: 'Flexwerker succesvol aangemeld',
    claimed: 'Flexwerker succesvol op shift geplaatst',
    downloadOvo: 'Het downloaden van de overeenkomst voor deze shiftclaim is succesvol',
    downloadExport: 'Het downloaden van deze export is succesvol',
    updateCheckout: 'Je tegenvoorstel is verstuurd!',
    bulkCreated: (succeeded: number, total: number) =>
      `Top! ${succeeded} van de ${total} shifts zijn succesvol geaccepteerd`,
    undoneNoShow: 'Deze flexwerker is nu niet meer als no-show gemarkeerd',
  },
};

export const projectAlerts = {
  success: {
    created: 'Project succesvol aangemaakt',
    updated: 'Project succesvol aangepast',
    deleted: 'Project succesvol verwijderd',
    archived: 'Project succesvol gearchiveerd',
  },
};

export const workApplicationsAlerts = {
  success: {
    accepted: 'Aanmelding is succesvol geaccepteerd',
    denied: 'Aanmelding is succesvol afgewezen',
  },
};

export const jobAlerts = {
  success: {
    created: 'Functie succesvol aangemaakt',
    updated: 'Functie succesvol aangepast',
    deleted: 'Functie succesvol verwijderd',
  },
};

export const companyAlerts = {
  success: {
    created: 'Bedrijf succesvol aangemaakt',
    updated: 'Bedrijf succesvol aangepast',
    deleted: 'Bedrijf succesvol verwijderd',
  },
  error: {
    noEmploymentTypeOffers: 'Er moet minimaal een arbeidsvorm geselecteerd zijn voor het beridij.',
  },
};

export const partnersAlerts = {
  success: {
    created: 'Partner succesvol aangemaakt',
    archived: 'Partner succesvol gearchiveerd',
    updated: 'Partner succesvol aangepast',
  },
};

export const partnerUserAlerts = {
  success: {
    created: 'Partner gebruiker succesvol aangemaakt',
    updated: 'Partner gebruiker succesvol aangepast',
    deleted: 'Partner gebruiker succesvol verwijderd',
  },
};

export const accreditationsAlerts = {
  success: {
    rejected: 'Het certificaat is afgewezen',
    approved: 'Het certificaat is goedgekeurd',
    updated: 'Het certificaat is succesvol aangepast',
    addedAndAccepted: 'Certificaat is aan de werker toegevoegd en goedgekeurd',
  },
};

export const branchOfficeAlerts = {
  success: {
    created: 'Vestiging succesvol aangemaakt',
    updated: 'Vestiging succesvol aangepast',
    deleted: 'Vestiging succesvol verwijderd',
  },
};

export const departmentAlerts = {
  success: {
    created: 'Afdelingen succesvol aangemaakt',
    updated: 'Afdelingen succesvol aangepast',
    deleted: 'Afdelingen succesvol verwijderd',
  },
};

export const flexWorkerAlerts = {
  success: {
    created: 'Flexwerker succesvol aangemaakt',
    updated: 'Flexwerker succesvol aangepast',
    deleted: 'Flexwerker succesvol verwijderd',
  },
  error: {
    created: 'Het aanmaken van deze flexwerker is mislukt',
    updated: 'Het aanpassen van deze flexwerker is mislukt',
    deleted: 'Het verwijderen van deze flexwerker is mislukt',
  },
};

export const userAlerts = {
  success: {
    created: 'Gebruiker succesvol aangemaakt',
    updated: 'Gebruiker succesvol aangepast',
    deleted: 'Gebruiker succesvol verwijderd',
    resendTempCredentials: 'De inloggegevens zijn succesvol opnieuw verstuurd',
  },
};

export const flexPoolAlerts = {
  success: {
    created: 'Flexpool succesvol aangemaakt',
    updated: 'Flexpool succesvol aangepast',
    deleted: 'Flexpool succesvol verwijderd',
    unsubscribed: 'Flexwerkers succesvol uit flexpool verwijderd',
    removedFlexWorkerFromFlexPool: 'Flexwerker succesvol uit flexpool verwijderd',
  },
};

export const accessAlerts = {
  error: {
    illegal: 'Je hebt niet de juiste rechten om deze actie uit te voeren',
  },
};

export const billingEntityAlerts = {
  success: {
    created: 'Facturatiegegevens succesvol aangemaakt',
    updated: 'Facturatiegegevens succesvol aangepast',
    deleted: 'Facturatiegegevens succesvol verwijderd',
  },
};

export const shiftPartnerAlerts = {
  success: {
    opened: 'Shift is succesvol opengesteld voor partners',
  },
};

export const flexWorkerPaydayOnboardingAlerts = {
  success: {
    created: 'De flexwerker is succesvol aangemeld bij Payday.',
  },
  error: {
    created: 'De aanmelding van de flexwerker bij Payday is mislukt.',
  },
};

export const partnerWorkerAlerts = {
  success: {
    created: 'Kandidaat succesvol aangemaakt',
    updated: 'Kandidaat succesvol aangepast',
    deleted: 'Kandidaat succesvol verwijderd',
  },
};

export const partnerPlacementAlerts = {
  success: {
    deleted: 'Plaatsing succesvol verwijderd',
    bulkDeleted: 'Plaatsingen succesvol verwijderd',
  },
};

export const partnerCounterOfferAlerts = {
  success: {
    approved: 'Tegenvoorstel is goedgekeurd',
    hoursSubmitted: 'Uren zijn succesvol ingediend',
  },
};

export const plafromSettingsAlerts = {
  success: {
    updated: 'Platform instellingen succesvol aangepast',
  },
};

export const bankAccountAlerts = {
  success: {
    approved: 'Succesvol goedgekeurd',
    reject: 'Succesvol afgekeurd',
  },
};

export const idCheckAlerts = {
  success: {
    approved: 'Succesvol goedgekeurd',
    reject: 'Succesvol afgekeurd',
  },
};
