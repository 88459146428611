import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
import { useFormContext } from 'react-hook-form';
import { InputSelectOption } from '@types';
import { getMinDate } from 'shared/utils/get-min-date';
import { getHelperTexts } from 'shared/components/shift-forms/components/forms/shared/get-helper-texts/get-helper-texts';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { useGetJob } from '../../../hooks/query-hooks/use-get-job';
import { ProjectFormTypeAll } from '../types/project-form.types';
import { DepartmentFormElement } from '../components/elements/department-form-element';
import { JobFormElement } from '../components/elements/job-form-element';
import { StartDateElement } from '../components/elements/start-date-element';
import { EndDateElement } from '../components/elements/end-date-element';
import { HourlyRateElement } from '../components/elements/hourly-rate-element';
import { WeeklyHoursElement } from '../components/elements/weekly-hours-element';

export type ProjectFormInputsProps = {
  // only for create
  jobsData?: { id: string; name: string; flexPoolOnly: boolean }[];
  departments: InputSelectOption[];
  mode: FormMode;
  isProjectWithActivePlacement: boolean;
  workDetails: DetailsForWorkTemplate | undefined;
};

type ProjectFormInputs = React.ReactNode[];

export const useGetProjectFormInputs = ({
  jobsData,
  departments,
  mode,
  isProjectWithActivePlacement,
}: ProjectFormInputsProps): ProjectFormInputs => {
  const { control, watch, setValue } = useFormContext<ProjectFormTypeAll>();

  const watchedDepartment = watch('departmentId');
  const watchedJobId = watch('jobId');

  const { data: jobData, isLoading: isSelectedJobLoading } = useGetJob(watchedJobId);
  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const helperTexts = getHelperTexts(jobData);

  const jobOptionsForCreateForm = jobsData?.length
    ? jobsData.map((job) => ({
        value: job.id,
        label: (
          <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>{job.name}</FlexPoolOnlyIndicator>
        ),
      }))
    : [];

  return [
    <DepartmentFormElement
      key="departmentId"
      control={control}
      disabled={mode !== 'create'}
      options={departments}
      onChange={() => {
        setValue('jobId', '');
      }}
    />,
    mode === 'create' ? (
      <JobFormElement
        key="jobId"
        control={control}
        disabled={mode !== 'create' || !watchedDepartment}
        options={jobOptionsForCreateForm}
      />
    ) : null,
    <StartDateElement
      key="startDate"
      control={control}
      disabled={isProjectWithActivePlacement}
      minDate={minDate}
    />,
    <EndDateElement
      key="endDate"
      control={control}
      disabled={isProjectWithActivePlacement}
      minDate={minDate}
    />,
    <HourlyRateElement
      key="hourlyRate"
      control={control}
      disabled={isSelectedJobLoading || isProjectWithActivePlacement}
      existingRate={jobData?.freelanceJob?.hourlyRateCents}
      helperTexts={helperTexts}
      employmentTypes={{
        isTemp: !!jobData?.tempWorkJob,
        isFreelance: !!jobData?.freelanceJob,
      }}
    />,
    <WeeklyHoursElement
      key="weeklyHours"
      control={control}
      disabled={isSelectedJobLoading}
      existingHours={undefined}
    />,
  ];
};
