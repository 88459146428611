import { Box, styled } from '@mui/material';
import { WorkTemplateJobOrWorkDetails } from './work-template-job-or-work-details';
import { DetailsForWorkTemplate } from './work-form-details.types';

export const WorkFormDetails = ({
  workDetails,
}: {
  workDetails: DetailsForWorkTemplate | undefined;
}) => {
  return (
    <Disclaimer>
      <ScrollableContainer>
        <Box width={'100%'}>
          <WorkTemplateJobOrWorkDetails workDetails={workDetails} />
        </Box>
      </ScrollableContainer>
    </Disclaimer>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
}));

const ScrollableContainer = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '22px',
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));
