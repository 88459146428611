import { FreelanceJobDetailsWithId, TempWorkJobDetailsWithId } from '@types';
import { HqApiService } from './hqApiService';
import { GetJobDetailsForWorkTemplateHQ } from './job-service.types';

export class JobService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getJob = async <T = TempWorkJobDetailsWithId | FreelanceJobDetailsWithId>(
    jobId: string
  ): Promise<T> => {
    const response = await this.hqApiService.get<T>({
      path: `jobs/${jobId}`,
    });

    return response;
  };

  public getJobDetailsForWorkTemplate = async (
    jobId: string
  ): Promise<GetJobDetailsForWorkTemplateHQ> => {
    return await this.hqApiService.get<GetJobDetailsForWorkTemplateHQ>({
      path: `jobs/${jobId}/details-for-work-template`,
    });
  };
}
