import { HqApiService } from './hqApiService';
import { GetWorkDetailsForWorkTemplateHQ } from './work-service.types';

export class WorkService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getWorkDetailsForWorkTemplate = async (
    workId: string
  ): Promise<GetWorkDetailsForWorkTemplateHQ> => {
    return await this.hqApiService.get<GetWorkDetailsForWorkTemplateHQ>({
      path: `work/${workId}/details-for-work-template`,
    });
  };
}
