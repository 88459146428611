import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useGetCompanyEmploymentTypes } from 'hq/hooks/queryHooks/companies/use-get-company-employment-types';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { HQShiftFormData } from './types/hq-shift-form.types';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { useHqGetShiftCreateData } from '../hooks/hq/use-hq-get-shift-create-data';
import { useHqGetJob } from '../hooks/hq/use-hq-get-job';
import { FormMode } from '../components/forms/shared/types';

type ShiftFormProps = {
  mode: FormMode;
  workDetails: DetailsForWorkTemplate | undefined;
};

export const HqShiftForm = ({ mode, workDetails }: ShiftFormProps) => {
  const { watch, reset } = useFormContext<HQShiftFormData>();
  const { companyId, jobId } = watch();
  const shiftCreateDataQuery = useHqGetShiftCreateData(companyId);
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes(companyId);
  const selectedJobQuery = useHqGetJob(jobId);

  useEffect(() => {
    if (mode === 'create') {
      reset({ companyId });
    }
  }, [companyId, mode]);

  return (
    <ShiftForm
      mode={mode}
      isDisabled={shiftCreateDataQuery.status !== 'success' || mode === 'read'}
      shiftCreateData={
        shiftCreateDataQuery.data ?? { branchOfficesWithDepartments: [], breakOptions: [] }
      }
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      jobData={selectedJobQuery.data}
      allowDateBeforeToday={true}
      workDetails={workDetails}
    />
  );
};
