import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { useGetDepartmentsAsSelect } from '../../hooks/use-get-departments';

type Props = {
  companyId: string;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectDuplicateFormProvider = ({ companyId, workDetails }: Props) => {
  const { id: projectId } = useParams<'id'>();
  invariant(projectId, 'Shift ID was supposed to be passed via route param');

  const departmentsQuery = useGetDepartmentsAsSelect(companyId, { includeArchived: false });

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectForm
      mode="duplicate"
      isProjectWithActivePlacement={false}
      departments={departmentsQuery.data}
      workDetails={workDetails}
    />
  );
};
