import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService as hqShiftService } from 'hq/services';
import { shiftService as ogpShiftService } from 'ogp/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useQuery } from 'react-query';
import { useSiteDetection } from '../../../../hooks';

const useGetShiftPartners = (shiftId: string, enabled: boolean) => {
  const { isHQ } = useSiteDetection();
  const queryKey = isHQ
    ? QUERY_KEYS_HQ.shiftPartner(shiftId)
    : QUERY_KEYS_OGP.shiftPartner(shiftId);

  const queryFn = isHQ
    ? async () => await hqShiftService.getShiftPartners(shiftId)
    : async () => await ogpShiftService.getShiftPartners(shiftId);

  return useQuery(queryKey, queryFn, { enabled });
};

export { useGetShiftPartners };
