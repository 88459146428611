import { OgpApiService } from './ogp-api-service';
import { GetWorkDetailsForWorkTemplateOGP } from './work-service.types';

export class WorkService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public getWorkDetailsForWorkTemplate = async (
    workId: string
  ): Promise<GetWorkDetailsForWorkTemplateOGP> => {
    return await this.ogpApiService.get<GetWorkDetailsForWorkTemplateOGP>({
      path: `work/${workId}/details-for-work-template`,
    });
  };
}
