import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { useInfiniteQuery } from 'react-query';
import { AccreditationParams } from '../accreditations.types';

export const useGetAccreditations = (filterParams: AccreditationParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_HQ.accreditations(filterParams),
    queryFn: async ({ pageParam = 0 }) =>
      await accreditationsService.getAccreditations({
        pageParam,
        pageSizeParam: 30,
        certificate: filterParams.certificate,
        search: filterParams.search,
        order: filterParams.order,
        state: filterParams.state,
        orderBy: filterParams.orderBy,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
    // No caching here because this data is highly volatile. Showing old data is very undesirable.
    // This is needed for pagination. We want to keep showing the previous data when fetching the new page.
    keepPreviousData: true,
  });
};
