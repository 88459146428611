import { Control } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const WeeklyHoursElement = ({
  control,
  disabled,
  existingHours,
}: {
  control: Control<ProjectFormTypeAll>;
  disabled: boolean;
  existingHours: number | undefined;
}) => {
  return (
    <LwFormNumberInput
      name="weeklyHours"
      label="Uren per week"
      control={control}
      rules={{ required: 'Voer uren per week in' }}
      step={0.01}
      min={0}
      placeholder={`${(existingHours || 0) / 100}`}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};
