import { RenderUnsettledUI } from 'shared/components';
import {
  useGetFilterBusinessUnitsData,
  useGetFilterJobTypesData,
  useGetFilterWorkersData,
} from 'partner/hooks/use-get-filters-data';
import { PlacementsFiltersDataProviderProps } from './placements-filters.types';
import { PlacementsFilters } from './placements-filters';

export const PlacementsFiltersDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: PlacementsFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetFilterBusinessUnitsData({
    jobTypeIds: appliedFilters.jobType,
  });

  const jobTypeQuery = useGetFilterJobTypesData({
    companyId: appliedFilters.company,
    branchOfficeId: appliedFilters.branchOffice,
    departmentId: appliedFilters.department,
  });

  const workersQuery = useGetFilterWorkersData();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (workersQuery.status !== 'success') {
    return <RenderUnsettledUI data={workersQuery} />;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <PlacementsFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
        workers: workersQuery.data,
      }}
    />
  );
};
