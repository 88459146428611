import { ContextMenuItem, LevelTableHeaderProps, Table, useOnScrollDown } from 'redesign';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import { RenderUnsettledUI } from 'shared/components';
import { getAccreditationsColumnDefinition } from './get-accreditations-column-definition';
import { getAccreditationsTableData } from './get-accreditations-table-data';
import {
  AccreditationOrderBy,
  AccreditationParams,
  SetAccreditationParams,
} from './accreditations.types';
import {
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
} from './accreditations-table-provider.types';
import { useGetAccreditations } from './hooks/use-get-accreditations';
import {
  AccreditationType,
  GetAccreditationsResponse,
} from '../../../services/accreditations-service.types';
import { useHqRelativePath } from '../../../hooks/useHqRelativePath';
import { hqRoutes } from '../../../Routes';

type Order = 'asc' | 'desc';

type AccreditationsTableProviderProps = {
  params: AccreditationParams;
  setParams: SetAccreditationParams;
};

type AccreditationsTableProps = {
  accreditationsQuery: InfiniteQueryObserverSuccessResult<GetAccreditationsResponse, unknown>;
  params: AccreditationParams;
  setParams: SetAccreditationParams;
};

export const AccreditationsTableProvider = ({
  params,
  setParams,
}: AccreditationsTableProviderProps) => {
  const accreditationsQuery = useGetAccreditations(params as AccreditationParams);

  if (accreditationsQuery.status !== 'success') {
    return <RenderUnsettledUI data={accreditationsQuery} />;
  }
  return (
    <AccreditationsTable
      accreditationsQuery={accreditationsQuery}
      params={params}
      setParams={setParams}
    />
  );
};

export const AccreditationsTable = ({
  accreditationsQuery,
  params,
  setParams,
}: AccreditationsTableProps) => {
  const { data } = accreditationsQuery;
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const columns = useMemo(() => getAccreditationsColumnDefinition(), []);

  const flatData = useMemo(() => data.pages.flatMap((page) => page.items), [data]);
  const tableData = useMemo(() => getAccreditationsTableData(flatData), [flatData]);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: accreditationsQuery,
    flatData: flatData,
  });

  const handleSortClick = (
    cell: LevelTableHeaderProps<AccreditationsColumnDefinition, AccreditationTableDefinition>
  ) => {
    let newOrder: Order = params.order === 'desc' ? 'asc' : 'desc';
    // when switching orderBy, start with the defualt desc order
    if (params.orderBy !== cell.column.id) {
      newOrder = 'desc';
    }

    if (Object.values(AccreditationOrderBy).includes(cell.column.id as AccreditationOrderBy)) {
      setParams({ orderBy: cell.column.id as AccreditationOrderBy });
    }
    setParams({ order: newOrder });
  };

  const getMenuActionsForAccreditation = (
    flexWorkerId: string,
    certificateId: string,
    accreditationType: AccreditationType
  ) => {
    return [
      <ContextMenuItem
        key="detail"
        onClick={() => {
          setParams({ flexWorkerId, certificateId, accreditationType });
        }}
      >
        Bekijken
      </ContextMenuItem>,
      <ContextMenuItem
        key="edit"
        onClick={() => {
          navigate(
            generatePath(hqRoutes.AccreditationsEdit, {
              flexWorkerId,
              certificateId,
              accreditationType,
            })
          );
        }}
      >
        Aanpassen
      </ContextMenuItem>,
    ];
  };

  return (
    <>
      <Box height="90%">
        <Table<AccreditationsColumnDefinition, AccreditationTableDefinition>
          stickyHeader
          columns={columns}
          data={tableData}
          sortState={params.order}
          getRowActions={getMenuActionsForAccreditation}
          onSortClick={handleSortClick}
          onScroll={fetchIfBottomScrolled}
          orderBy={params.orderBy}
        />
      </Box>
    </>
  );
};
