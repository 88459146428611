import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { invariant } from 'shared/utils/utils';
import { DateTime } from 'luxon';
import { AccreditationFormTypes } from './accreditation-form-hq';
import { getAccreditationType } from './utils';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import {
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from '../../../../../ogp/providers/dialog-provider';
import { accreditationsAlerts, useAlert } from '../../../../../shared/components/alerts';
import { useUpdateAccreditation } from '../hooks/use-update-accreditation';
import { IconApproved, IconCancel } from '../../../../../assets/img';
import { LwButton } from '../../../../../redesign';
import { hqRoutes } from '../../../../Routes';

export const EditAccreditationToolboxHQ = () => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useUpdateAccreditation();
  const { watch, formState } = useFormContext<AccreditationFormTypes>();

  const {
    expirationDate,
    files,
    startDate,
    certificate,
    flexWorkerOption,
    accreditationType,
    isWithoutDocument,
  } = watch();

  const updateAccreditation = () => {
    invariant(certificate, 'Certificaat ID ontbreekt');

    openDialog(
      {
        title: expirationDate
          ? 'Wil je deze accreditatie updaten?'
          : 'Weet je zeker dat je deze accreditatie zonder vervaldatum wilt updaten?',
        secondaryButton: (props) => (
          <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </PrimaryButton>
        ),
      },
      () => {
        disableDialog();
        const expirationAsDateTime = expirationDate ? DateTime.fromISO(expirationDate) : null;
        const startDateAsDateTime = startDate ? DateTime.fromISO(startDate) : null;

        const mutationData = {
          flexWorkerId: flexWorkerOption?.value || '',
          certificateId: certificate || '',
          expirationDate: expirationAsDateTime,
          startDate: startDateAsDateTime,
          accreditations: [{ id: certificate, files }],
          accreditationType: getAccreditationType(accreditationType),
        };

        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(accreditationsAlerts.success.updated);
            navigate(generatePath(hqRoutes.Accreditations));
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };
  const submitDisabled =
    !formState.isDirty || (!isWithoutDocument && (!files || files.length === 0));

  return (
    <>
      <LwButton
        type="submit"
        iconColorMode="stroke"
        color="primary"
        startIcon={<IconApproved />}
        onClick={updateAccreditation}
        disabled={submitDisabled}
      >
        Aanpassen
      </LwButton>
      <LwButton
        onClick={() => navigate(generatePath(hqRoutes.Accreditations))}
        iconColorMode="fill"
        color="secondary"
        startIcon={<IconCancel />}
      >
        Annuleer
      </LwButton>
    </>
  );
};
