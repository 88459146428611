import { Table, useOnScrollDown } from 'redesign';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import {
  HqProjectPlanningColumnDefinition,
  HqProjectPlanningTableOptions,
  INITIAL_PAGE,
} from './project-planning-table.types';
import { useGetProjectPlanningProjects } from './hooks/use-get-project-planning-projects';
import { getProjectPlanningTableData } from './utils/get-project-planning-table-data';
import { getProjectPlanningColumnDefinition } from './utils/get-project-planning-column-definition';
import { ProjectPlanningPageParams } from './hooks/use-project-planning.types';
import { GetProjectPlanningProjectsResponse } from '../../../../../services/project-planning-service.types';

type ProjectPlanningTableProps = {
  params: ProjectPlanningPageParams;
  setParams: (params: Partial<ProjectPlanningPageParams>) => void;
};

export const ProjectPlanningTableDataProvider = ({
  params,
  setParams,
}: ProjectPlanningTableProps) => {
  const projectsQuery = useGetProjectPlanningProjects({
    toDate: params.toDate ?? undefined,
    company: params.company ?? undefined,
    branchOffice: params.branchOffice ?? undefined,
    department: params.department ?? undefined,
    jobType: params.jobType ?? undefined,
    hasApplicants: params.hasApplicants ?? undefined,
    filledStatus: params.filledStatus ?? undefined,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
    isFreelance: params.isFreelance ?? undefined,
    isTempWork: params.isTempWork ?? undefined,
  });

  if (projectsQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectsQuery} />;
  }

  return <ProjectPlanningTable projectsQuery={projectsQuery} setParams={setParams} />;
};

const ProjectPlanningTable = ({
  projectsQuery,
  setParams,
}: {
  projectsQuery: InfiniteQueryObserverSuccessResult<GetProjectPlanningProjectsResponse, unknown>;
  setParams: ProjectPlanningTableProps['setParams'];
}) => {
  const { data } = projectsQuery;

  const flatData = useMemo(() => data.pages.flatMap((page) => page.items), [data]);
  const tableData = useMemo(() => getProjectPlanningTableData(flatData), [flatData]);

  const columns = useMemo(() => getProjectPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: projectsQuery,
    flatData: tableData,
  });

  const openProjectDetail = useCallback(
    (projectDetailId: string) => {
      setParams({ projectDetailId });
    },
    [setParams]
  );

  return (
    <Box maxHeight="100%">
      <Table<HqProjectPlanningColumnDefinition, HqProjectPlanningTableOptions>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        data-testid="project-planning-virtual-table"
        selectable={true}
        onShowDetail={openProjectDetail}
      />
    </Box>
  );
};
