import { Box, Grid, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { BranchOfficeFormData, vatListOptions } from '@types';
import { useGeocode } from 'ogp/hooks/queryHooks/locations/use-geocode';
import { LwFormAutocomplete, LwFormInput, LwFormMultiSelect, LwFormSelect } from 'redesign';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { IconWarning } from 'assets/img';
import { RenderUnsettledUI } from 'shared/components';
import { GetBranchOfficeCreateDataResponse } from 'ogp/services/branch-office-service.types';
import { useDebounce } from 'shared/hooks/use-debounce';
import { useGetBranchOfficeCreateData } from '../use-get-branch-office-create-data';

type FormProviderProps = {
  state: 'read' | 'update' | 'create';
  disabled?: true;
};

type FormProps = FormProviderProps & {
  createData: GetBranchOfficeCreateDataResponse;
};

export const BranchOfficeFormDataProvider = ({ state }: FormProviderProps) => {
  const createDataQuery = useGetBranchOfficeCreateData();

  if (createDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={createDataQuery} />;
  }

  return <BranchOfficeForm state={state} createData={createDataQuery.data} />;
};

const BranchOfficeForm = ({ disabled, state, createData }: FormProps) => {
  const { allowReverseChargingVAT } = useFeatureFlagContext();
  const { control, setValue, getValues } = useFormContext<BranchOfficeFormData>();
  const vatOptions = useMemo(
    () => vatListOptions(allowReverseChargingVAT),
    [allowReverseChargingVAT]
  );
  const defaultAddress = React.useRef(getValues().location?.address ?? '');
  const [input, setInput] = React.useState(defaultAddress.current);
  const debouncedInput = useDebounce(input, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== defaultAddress.current);
  const { data, isFetching } = geoQuery;
  const ref = React.useRef<HTMLFormElement | null>(null);
  const billingEntitiesOptions = createData.billingEntities;
  const flexPoolsOptions = createData.companyFlexPools;

  return (
    <form aria-label="Branch office form" ref={ref}>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={6}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een vestigingsnaam in' }}
            label="Vestigingsnaam"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="companyNameForOVO"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam voor de OVO in' }}
            label="Bedrijfsnaam voor OVO"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="legalPerson"
            control={control}
            rules={{ required: 'Voer een wettelijke vertegenwoordiger in' }}
            label="Wettelijke vertegenwoordiger"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="cocNumber"
            control={control}
            rules={{
              required: 'Voer een KVK-nummer in',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Een KVK-nummer bestaat uit acht cijfers',
              },
            }}
            label="KVK-nummer"
            placeholder="12345678"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormAutocomplete
            label="Locatie"
            name="location"
            control={control}
            rules={{ required: 'Voer een locatie in' }}
            disableClearable={true}
            getOptionLabel={(option) => option.address}
            isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
            getOptionKey={(option) => option.hereId ?? ''}
            onChange={(_event, value) => {
              if (value) {
                setValue('location', value);
              }
            }}
            onInputChange={(_e, value) => {
              setInput(value);
            }}
            disabled={disabled}
            options={data ? data : []}
            isLoading={isFetching}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="locationUrl"
            control={control}
            label="Locatie (maps link)"
            placeholder="Maps link"
            rules={{ required: 'Voer een locatie link in' }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="VAT"
            control={control}
            options={vatOptions}
            label="BTW-tarief"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="costCenter"
            label="Kostenplaats"
            control={control}
            disabled={disabled}
          />
        </Grid>
        {state === 'create' ? (
          <Grid item xs={12}>
            <DepartmentInfoWrap>
              <InfoTextWrap>
                <IconWarning width={20} />
                <Typography variant="h2">Standaard afdeling</Typography>
              </InfoTextWrap>
              <Typography variant="body2">
                Er wordt automatisch een afdeling gecreëerd voor het nieuwe filiaal dat u aan het
                aanmaken bent. Selecteer de flexpools die u aan deze nieuwe afdeling wilt toewijzen.
              </Typography>
            </DepartmentInfoWrap>
            <LwFormMultiSelect
              name="departmentFlexPoolIds"
              control={control}
              options={flexPoolsOptions ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              label="Flexpools"
            />
            <LwFormSelect
              name="billingEntityId"
              options={billingEntitiesOptions}
              label="Facturatiegegevens"
              control={control}
              rules={{ required: 'Selecteer een facturatiegegevens' }}
            />
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};

const DepartmentInfoWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flexStart',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  marginRight: theme.spacing(2),
}));

const InfoTextWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));
