import { Control } from 'react-hook-form';
import { LwFormDate } from 'redesign';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const EndDateElement = ({
  control,
  disabled,
  minDate,
}: {
  control: Control<ProjectFormTypeAll>;
  disabled: boolean;
  minDate: string;
}) => {
  return (
    <LwFormDate
      name="endDate"
      label="Einddatum"
      control={control}
      rules={{ required: 'Voer een einddatum in' }}
      minDate={minDate}
      data-testid="end-date"
      disabled={disabled}
    />
  );
};
