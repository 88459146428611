import { AccreditationType } from 'hq/services/accreditations-service.types';

export const getAccreditationType = (value: string | null): AccreditationType => {
  if (value && value in AccreditationType) {
    return value as AccreditationType;
  }
  return AccreditationType.full;
};

export const accreditationTypeOptions = [
  { value: 'receipt', label: 'Betaalbewijs' },
  { value: 'full', label: 'Volledig certificaat' },
];
