import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useInfiniteQuery } from 'react-query';
import { FirstParamOfFn } from '@types';

const useGetShiftPlanningShifts = (
  params: FirstParamOfFn<typeof shiftPlanningService.getShiftPlanningShifts>
) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_HQ.shiftPlanningShiftsFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await shiftPlanningService.getShiftPlanningShifts({
        pageParam,
        fromDate: params.fromDate,
        pageSizeParam: params.pageSizeParam,
        company: params.company,
        branchOffice: params.branchOffice,
        department: params.department,
        jobType: params.jobType,
        filledStatus: params.filledStatus,
        hasApplicants: params.hasApplicants,
        partner: params.partner,
        isFreelance: params.isFreelance,
        isTempWork: params.isTempWork,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
export { useGetShiftPlanningShifts };
