import { AccreditationType } from 'hq/services/accreditations-service.types';

export type State = (typeof stateValues)[number];
export const stateValues = ['all', 'verified', 'rejected', 'expired', 'pending'] as const;
export const translatedStates = [
  'Geverifieerd',
  'Verlopen',
  'Afgewezen',
  'In behandeling',
  'Alle',
] as const;

export const translateState = (state: string) => {
  switch (state) {
    case 'verified':
      return 'geverifieerd';
    case 'pending':
      return 'in behandeling';
    case 'rejected':
      return 'afgewezen';
    case 'expired':
      return 'verlopen';
    case 'all':
      return 'alle';
    default:
      return '';
  }
};

export const ACCREDITATIONS_INITIAL_VALUES = {
  flexWorkerId: {
    initialValue: undefined,
    type: 'string',
  },
  certificateId: {
    initialValue: undefined,
    type: 'string',
  },
  order: {
    initialValue: 'desc',
    type: 'string',
  },
  orderBy: {
    initialValue: 'createdAt',
    type: 'string',
  },
  search: {
    initialValue: undefined,
    type: 'string',
  },
  certificate: {
    initialValue: 'alle',
    type: 'string',
  },
  state: {
    initialValue: stateValues[0],
    type: 'string',
  },
  accreditationType: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export type AccreditationParams = {
  pageParam: number;
  pageSizeParam: number;
  flexWorkerId?: string;
  certificateId?: string;
  order: 'asc' | 'desc';
  orderBy: AccreditationOrderBy;
  search?: string;
  certificate: string;
  state: State;
  accreditationType?: AccreditationType;
};

export enum AccreditationOrderBy {
  createdAt = 'createdAt',
  expirationDate = 'expirationDate',
  startDate = 'startDate',
}

export type SetAccreditationParams = (params: Partial<AccreditationParams>) => void;
