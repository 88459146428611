import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments/hooks';
import { ProjectFormTypeDuplicate } from 'shared/components/project-forms/types/project-form.types';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { ProjectDuplicateFormPage } from './form/project-duplicate-form-page';
import { useGetProjectDetail } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';

export const ProjectDuplicateDataProvider = () => {
  const { id: projectId } = useParams<'id'>();
  invariant(projectId, 'Project ID is not specified');

  const projectQuery = useGetProjectDetail(projectId);
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });
  const workDetailsQuery = useGetWorkDetails(projectId);

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  const defaultValues: ProjectFormTypeDuplicate = {
    departmentId: projectQuery.data.department.id,
    startDate: projectQuery.data.startDate,
    endDate: projectQuery.data.endDate,
    weeklyHours: projectQuery.data.weekHourVolume,
    hourlyRate: projectQuery.data.hourlyRateCents / 100,
  };

  return (
    <ProjectDuplicateFormPage
      ancestorProjectId={projectId}
      defaultValues={defaultValues}
      successMessage={`Project ${projectQuery.data.name} duplicated`}
      pageTitle={`Project ${projectQuery.data.name} dupliceren`}
      departments={departmentsQuery.data}
      workDetails={workDetailsQuery.data}
    />
  );
};
