import { Box, Divider, Grid, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { isFreelanceJob } from '@types';
import { FormCheckbox } from 'redesign';
import { FlexPoolsSection } from 'ogp/components/views/planning/jobs/forms/shared/flex-pools/flex-pools-section';
import { FormLocation } from 'redesign/forms/location/form-location';
import {
  FreelanceShiftFormData,
  TempWorkShiftFormData,
} from 'shared/components/shift-forms/types/shift-forms.types';
import { WorkFormDetails } from 'shared/components/work-forms/details/work-form-details';
import { useGetDepartmentAsDetail } from './hooks/use-get-department-as-detail';
import { ShiftFormProps } from '../shared/types';
import { JobInputs } from '../shared/inputs/job-inputs';
import { ShiftDateTimeInputs } from '../shared/inputs/date-time-inputs';
import { BreakMinutesInput } from '../shared/inputs/break-minutes-input';
import { MaxClaimantsElement } from '../shared/inputs/max-claimants-input';
import { HourlyRateInput } from '../shared/inputs/hourly-rate-input';
import { getHelperTexts } from '../shared/get-helper-texts/get-helper-texts';
import { MutableUntilHoursInput } from '../shared/inputs/mutable-until-input';

export const ShiftForm = ({
  isDisabled,
  mode,
  shiftCreateData,
  jobData,
  employmentTypeOfCompany,
  allowDateBeforeToday,
  workDetails,
}: ShiftFormProps) => {
  const { setValue, watch, control } = useFormContext<
    FreelanceShiftFormData | TempWorkShiftFormData
  >();
  const departmentId = watch('departmentId');
  const { data: department } = useGetDepartmentAsDetail(departmentId, departmentId !== undefined);
  const helperTexts = useMemo(() => getHelperTexts(jobData), [jobData]);
  const contractFieldsDisabled =
    isDisabled || (!allowDateBeforeToday && mode === 'disableContractFields');

  const selectedJobId = watch('jobId') as string;
  const isFreelanceCheckboxChecked = watch('isFreelance');

  const areCheckboxesDisabled = useMemo(() => {
    const jobEmploymentType = {
      isFreelance: !!jobData?.freelanceJob,
      isTempwork: !!jobData?.tempWorkJob,
    };
    const companyAllowsBoth =
      employmentTypeOfCompany && Object.values(employmentTypeOfCompany).every(Boolean);

    const jobAllowsBoth = jobEmploymentType && Object.values(jobEmploymentType).every(Boolean);
    const isEnabled = !isDisabled && selectedJobId && companyAllowsBoth && jobAllowsBoth;

    return !isEnabled;
  }, [employmentTypeOfCompany, jobData, selectedJobId, isDisabled]);

  const isJobSelectorVisible = mode === 'create';

  //  setting the values from job only if company allows the employment type (edge case)
  useEffect(() => {
    if (jobData && employmentTypeOfCompany) {
      const { offersFreelanceWork, offersTempWork } = employmentTypeOfCompany;

      setValue('isFreelance', !!jobData?.freelanceJob && offersFreelanceWork);
      setValue('isTempwork', !!jobData?.tempWorkJob && offersTempWork);
    }
  }, [jobData, employmentTypeOfCompany]);

  useEffect(() => {
    if (!jobData) {
      return;
    }
    if (mode !== 'create') {
      return;
    }

    setValue('breakMinutes', jobData.breakMinutes);
    setValue('flexPools', jobData.flexPools);
    setValue('location', jobData.location);
    if (isFreelanceJob(jobData) && employmentTypeOfCompany?.offersFreelanceWork) {
      setValue('hourlyRate', jobData.freelanceJob.hourlyRateCents / 100);
      setValue('mutableUntilHours', jobData.freelanceJob.mutableUntilMinutes / 60);
    } else {
      setValue('hourlyRate', 0);
    }
  }, [jobData, mode, employmentTypeOfCompany]);

  useEffect(() => {
    if (mode !== 'create') {
      return;
    }
    setValue('flexPools', []);
  }, [department?.flexPools]);

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row" height="100%" flex="1">
        <form name="shift-form">
          <Grid container item spacing={4} xs={12}>
            <Grid item xs={12}>
              <JobInputs
                isJobDisabled={contractFieldsDisabled}
                isDepartmentDisabled={isDisabled}
                jobsData={department?.jobs ?? []}
                branchOfficesWithDepartments={shiftCreateData?.branchOfficesWithDepartments ?? []}
                isJobSelectorVisible={isJobSelectorVisible}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLocation disabled={isDisabled} />
            </Grid>
            <Grid item xs={12}>
              <ShiftDateTimeInputs
                disabled={contractFieldsDisabled}
                allowDateBeforeToday={allowDateBeforeToday}
                mode="create"
              />
            </Grid>
            <Grid item xs={6}>
              <MaxClaimantsElement disabled={isDisabled} />
            </Grid>
            <Grid item xs={6}>
              <BreakMinutesInput
                options={shiftCreateData?.breakOptions ?? []}
                disabled={contractFieldsDisabled}
                helperText={helperTexts.breakMinutes}
              />
            </Grid>
            <Grid item xs={12}>
              <FlexPoolsSection state={mode} flexPoolOptions={department?.flexPools ?? []} />
            </Grid>
            <Grid item xs={12}>
              <FormCheckbox
                name="isTempwork"
                label="Uitzendkracht"
                control={control}
                disabled={areCheckboxesDisabled}
              />
            </Grid>
            {/* FREELANCE FIELDS */}
            <Grid item xs={12}>
              <FormCheckbox
                name="isFreelance"
                label="Zelfstandige"
                control={control}
                disabled={areCheckboxesDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <HourlyRateInput
                disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                helperText={helperTexts.hourlyRate ?? ''}
                placeholder={`${(jobData?.freelanceJob?.hourlyRateCents || 0) / 100}`}
                required={isFreelanceCheckboxChecked}
              />
            </Grid>
            <Grid item xs={6}>
              <MutableUntilHoursInput
                disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                required={isFreelanceCheckboxChecked}
              ></MutableUntilHoursInput>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <StyledDivider orientation="vertical" />
      </Box>
      <Box flex="1" display="flex">
        <WorkFormDetails workDetails={workDetails} />
      </Box>
    </Box>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  marginRight: theme.spacing(7),
  width: 1,
}));
