import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox, LwFormAutocomplete } from 'redesign';
import { Box } from '@mui/material';
import { PartnerFilterFormContext, PartnersFilterData } from './filter-partners.types';
import { getFilterOptions } from '../../utils/utils';

type Props = { partners: PartnersFilterData[] };

export const FilterPartners = ({ partners }: Props) => {
  const { watch, control, setValue } = useFormContext<PartnerFilterFormContext>();
  const { partnersCheckboxValue } = watch();

  const partnersOptions = useMemo(
    () => partners.map((partner) => ({ label: partner.name, value: partner.id })),
    [partners]
  );

  return (
    <Box>
      <FormCheckbox name="partnersCheckboxValue" label="Partners" control={control} />
      {partnersCheckboxValue ? (
        <Box>
          <LwFormAutocomplete
            name="partner"
            isLoading={false}
            label="Partner"
            getOptionLabel={(value) => value?.label ?? ''}
            isOptionEqualToValue={(option, value) => option?.value === value.value}
            getOptionKey={(option) => option.value}
            options={partnersOptions}
            filterOptions={(options, state) => getFilterOptions(options, state)}
            control={control}
            onChange={(_, value) => {
              setValue('partner', value ? { ...value } : null);
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};
