import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { Table } from 'redesign';
import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { ReactNode, useMemo } from 'react';
import { getApplicantColumnDefinition } from './components/helpers/get-applicant-column-definition';
import { getApplicantTableData } from './components/helpers/get-applicant-table-data';
import {
  ApplicantColumnDefinition,
  ApplicantsTableOptions,
} from './components/helpers/applicant-table.types';
import { ApplicantDetail, ShiftDetailsApplicant } from './applicant-table.types';

type Props = {
  shiftDetails: ShiftDetailsApplicant;
  applicants: ApplicantDetail[];
  getActions: (application: ApplicantDetail, shiftData: string) => ReactNode[];
};

function ApplicantTable({ shiftDetails, applicants, getActions }: Props) {
  const columns = useMemo(
    () => getApplicantColumnDefinition(shiftDetails.employmentType, false),
    [shiftDetails.employmentType]
  );
  const tableData = useMemo(
    () => getApplicantTableData(applicants, shiftDetails),
    [applicants, shiftDetails]
  );
  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();

  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    openPopupProfile(flexWorkerId, relation);
  };

  return applicants.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn nog geen aanmeldingen!"
      subtitle="Er hebben zich nog geen flexwerkers aangemeld voor deze shift."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<ApplicantColumnDefinition, ApplicantsTableOptions>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        onProfileClick={onProfileClick}
        getRowActions={(application) => getActions(application, shiftDetails.startDate)}
      />
    </>
  );
}

export { ApplicantTable };
