import { formatCurrencyCents } from 'shared/utils/formatting';
import { MinimumRateColumnDefinition } from './minimum-rate-table.types';

type RawMinimumRateForJobTypesData = {
  id: string;
  name: string;
  minimumRateCents: number;
}[];

export const getMinimumRateTableData = (
  data: RawMinimumRateForJobTypesData
): MinimumRateColumnDefinition[] =>
  data.map((item) => ({
    name: item.name,
    minimumRateToEuros: `${formatCurrencyCents(item.minimumRateCents)}/ uur`,
    action: {
      id: item.id,
      minimumHourlyRate: item.minimumRateCents / 100,
    },
  }));
