import { DateTime } from 'luxon';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { useOgpDuplicateShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-duplicate-shift';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { paths } from 'ogp/paths';
import { useCallback } from 'react';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { transformData } from '../../utils/transform-data';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OgpShiftForm } from '../ogp-shift-form';
import { OGPShiftFormData } from '../../types/shift-forms.types';
import { getDefaultValuesForShiftForm } from '../../utils/get-default-values-for-shift-form';

type ShiftDuplicateProps = {
  shift: ShiftDetailResponse;
  shiftId: string;
  workDetails: DetailsForWorkTemplate;
};

export const OgpShiftDuplicateDataProvider = () => {
  const { id: shiftId } = useParams<'id'>();
  invariant(shiftId, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(shiftId);
  const workDetailsQuery = useGetWorkDetails(shiftId);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  return (
    <ShiftDuplicate shift={shiftQuery.data} shiftId={shiftId} workDetails={workDetailsQuery.data} />
  );
};

const ShiftDuplicate = ({ shift, shiftId, workDetails }: ShiftDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const updatePlanningPath = useUpdatePlanningPath();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const shiftDateTitle = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd').toFormat('dd-LL-yyyy');
  const mutation = useOgpDuplicateShift();

  const onCancel = useCallback(
    () =>
      navigate(
        generatePath(paths.Shifts, {
          search: createSearchParams({ shiftDetailId: shiftId }).toString(),
        })
      ),
    [navigate, generatePath, shiftId]
  );

  const formMethods = useForm<OGPShiftFormData>({
    defaultValues: { ...getDefaultValuesForShiftForm(shift), jobId: undefined },
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: OGPShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate(
        { ...data, ancestorShiftId: shiftId },
        {
          onSuccess: (shiftId, payload) => {
            const shiftDetailPath = updatePlanningPath({
              shiftDetailId: shiftId,
              date: DateTime.fromISO(payload.startDate),
            });
            alertSuccess(alerts.success.created);
            navigate(shiftDetailPath);
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateTitle} dupliceren`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <OgpShiftForm mode="duplicate" workDetails={workDetails} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
