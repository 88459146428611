import { Box } from '@mui/material';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { useMemo, useState } from 'react';
import { SearchBar, Toolbox } from 'redesign';
import { FlexWorkersForProjectFilterOptions } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { useDebounce } from 'shared/hooks/use-debounce';
import { CreatePlacementFlexWorkerFilters } from './create-placement-flex-worker-filters';
import { CreatePlacementTableProvider } from './create-placement-table-provider';
import { FlexWorkerTableOptions } from '../../../../flex-workers-for-work-table/flex-workers-for-work-table.types';

type Props = {
  projectId: string;
  getRowActions: FlexWorkerTableOptions['getRowActions'];
};

export const PlacementCreate = ({ ...props }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput, 300);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FlexWorkersForProjectFilterOptions>({});

  const handleOpeneningFilters = (val: boolean) => {
    setIsOpen(val);
  };

  const numberOfAppliedFilters = useMemo(
    () => Object.values(filterValues).filter((f) => f !== undefined).length,
    [filterValues]
  );

  return (
    <>
      <Box display="flex" marginTop={4} marginBottom={8}>
        <SearchBar onChange={setSearchInput} placeholder="Flex werker naam" />
        <Toolbox.PushRight>
          <CreatePlacementFlexWorkerFilters
            handleOpeneningFilters={handleOpeneningFilters}
            isOpen={isOpen}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
          />
          <FilterButton
            onClick={() => handleOpeneningFilters(true)}
            nrOfActiveFilters={numberOfAppliedFilters}
          />
        </Toolbox.PushRight>
      </Box>
      <CreatePlacementTableProvider
        filters={{ fullNameSearch: debouncedSearchInput || undefined, ...filterValues }}
        {...props}
      />
    </>
  );
};
