import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { RenderUnsettledUI } from 'shared/components';
import { ProjectCreateFormPage } from './project-create-form-page';

export const ProjectCreateDataProvider = () => {
  const companiesQuery = useGetCompanies();

  companiesQuery.data = companiesQuery.data?.filter((company) => company.offersFreelanceWork);

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return (
    <ProjectCreateFormPage
      companies={companiesQuery.data}
      successMessage={`Project aangemaakt`}
      pageTitle={`Maak nieuw project aan`}
    />
  );
};
