import { merge } from 'lodash';
import {
  BranchOfficeCreateDataProvider,
  BranchOfficeDetailDataProvider,
  BranchOfficeEditDataProvider,
  BranchOffices,
} from 'ogp/components/views/management/branch-offices';
import { JobCreateDataProvider } from 'ogp/components/views/planning/jobs/pages/create/job-create';
import { JobsDataProvider } from 'ogp/components/views/planning/jobs/jobs';
import { Planning } from 'ogp/components/views/planning/planning/planning';
import {
  UserCreate,
  UserDetailDataProvider,
  UserEditDataProvider,
} from 'ogp/components/views/management/users';
import { Users } from 'ogp/components/views/management/users/users';
import { useGetOGPUserInfo } from 'ogp/hooks/queryHooks/users/useGetOGPUserInfo';
import { useCompanyRelativePath } from 'ogp/hooks';
import { BaseLayout } from 'ogp/layouts/base-layout';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { RouteRenderer } from 'router';
import { hqPrefix, RouteDefinition } from 'Routes';
import { NotFound } from 'shared/components/views/404/404';
import { Unauthorized } from 'shared/components/views/unauthorized/Unauthorized';
import { useAlert } from 'shared/components/alerts';
import { ExtendedError, ExtendedErrorType } from 'shared/services/abstractApiService';
import { RenderUnsettledUI } from 'shared/components';
import { OgpShiftCreate } from 'shared/components/shift-forms/ogp/ogp-shift-create/ogp-shift-create';
import { routePrefix as ogpPrefix } from './settings';
import { CompanyDetail, CompanyEdit } from './components/views/management/company';
import { SharedCheckoutsArchive, SharedCheckoutsInbox } from './components/views/administration';
import {
  FlexPoolCreateDataProvider,
  FlexPoolEditDataProvider,
  FlexPools,
} from './components/views/planning/flex-pools';
import { RouteKeys, routes } from './Routes';
import { DialogProvider } from './providers/dialog-provider/dialog-provider';
import { JobDetailDataProvider } from './components/views/planning/jobs/pages/detail/job-detail';
import { JobEditDataProvider } from './components/views/planning/jobs/pages/edit/job-edit';
import { ProjectsPlanning } from './components/views/planning/projects-planning/projects-planning';
import { ProjectCreateDataProvider } from './components/views/planning/projects-planning/components/forms/project-create-data-provider';
import { ProjectUpdateDataProvider } from './components/views/planning/projects-planning/components/forms/project-update-data-provider';
import { Departments } from './components/views/management/departments';
import { DepartmentDetailDataProvider } from './components/views/management/departments/pages/department-detail';
import { DepartmentCreateDataProvider } from './components/views/management/departments/pages/create/department-create';
import { DepartmentEditDataProvider } from './components/views/management/departments/pages/edit/department-edit';
import {
  BillingEntityCreate,
  BillingEntityDetail,
  BillingEntityEditProvider,
} from './components/views/management/billing-entities';
import { Legal } from './components/views/management/legal';
import { ProjectDuplicateDataProvider } from './components/views/planning/projects-planning/components/forms/project-duplicate-data-provider';
import { BranchOfficeDuplicateDataProvider } from './components/views/management/branch-offices/pages/create/branch-office-duplicate';
import { DepartmentDuplicateDataProvider } from './components/views/management/departments/pages/create/department-duplicate';
import { BillingEntityDuplicateDataProvider } from './components/views/management/billing-entities/pages/create/billing-entity-duplicate';
import { JobDuplicateDataProvider } from './components/views/planning/jobs/pages/create/job-duplicate';
import { OgpShiftEditDataProvider } from '../shared/components/shift-forms/ogp/ogp-shift-edit/ogp-shift-edit';
import { OgpShiftMultiplyDataProvider } from '../shared/components/shift-forms/ogp/ogp-shift-multiply/ogp-shift-multiply';
import { OgpShiftDuplicateDataProvider } from '../shared/components/shift-forms/ogp/ogp-shift-duplicate/ogp-shift-duplicate';

// elements defined separately per route in order to prevent circular dependencies
const components: Record<RouteKeys, Partial<RouteDefinition>> = {
  Unauthorized: { element: <Unauthorized /> },
  BillingEntitiesDetail: { element: <BillingEntityDetail /> },
  BillingEntitiesEdit: { element: <BillingEntityEditProvider /> },
  BillingEntitiesCreate: { element: <BillingEntityCreate /> },
  BillingEntitiesDuplicate: { element: <BillingEntityDuplicateDataProvider /> },
  Jobs: { element: <JobsDataProvider /> },
  JobsEdit: { element: <JobEditDataProvider /> },
  JobsCreate: { element: <JobCreateDataProvider /> },
  JobsDuplicate: { element: <JobDuplicateDataProvider /> },
  JobsDetail: { element: <JobDetailDataProvider /> },
  Shifts: { element: <Planning /> },
  ShiftsEdit: { element: <OgpShiftEditDataProvider /> },
  ShiftsDuplicate: { element: <OgpShiftDuplicateDataProvider /> },
  ShiftsMultiply: { element: <OgpShiftMultiplyDataProvider /> },
  ShiftsCreate: { element: <OgpShiftCreate /> },
  FlexPools: { element: <FlexPools /> },
  Company: { element: <CompanyDetail /> },
  CompanyEdit: { element: <CompanyEdit /> },
  BranchOffices: { element: <BranchOffices /> },
  BranchOfficesEdit: { element: <BranchOfficeEditDataProvider /> },
  BranchOfficesCreate: { element: <BranchOfficeCreateDataProvider /> },
  BranchOfficesDuplicate: { element: <BranchOfficeDuplicateDataProvider /> },
  BranchOfficesDetail: { element: <BranchOfficeDetailDataProvider /> },
  Users: { element: <Users /> },
  UsersEdit: { element: <UserEditDataProvider /> },
  UsersCreate: { element: <UserCreate /> },
  UsersDetail: { element: <UserDetailDataProvider /> },
  CheckoutsInbox: { element: <SharedCheckoutsInbox /> },
  CheckoutsArchive: { element: <SharedCheckoutsArchive /> },
  FlexPoolCreate: { element: <FlexPoolCreateDataProvider /> },
  FlexPoolEdit: { element: <FlexPoolEditDataProvider /> },
  Projects: { element: <ProjectsPlanning /> },
  ProjectsCreate: { element: <ProjectCreateDataProvider /> },
  ProjectsDuplicate: { element: <ProjectDuplicateDataProvider /> },
  ProjectsEdit: { element: <ProjectUpdateDataProvider /> },
  Departments: { element: <Departments /> },
  DepartmentDetail: { element: <DepartmentDetailDataProvider /> },
  DepartmentCreate: { element: <DepartmentCreateDataProvider /> },
  DepartmentDuplicate: { element: <DepartmentDuplicateDataProvider /> },
  DepartmentEdit: { element: <DepartmentEditDataProvider /> },
  Legal: { element: <Legal /> },
};

// TODO stop merging this as a side effect
const items = merge(routes, components) as Record<string, RouteDefinition>;

const companyBasePath = `:companyHash`;

const Router = () => {
  const { alertError } = useAlert();
  const generatePath = useCompanyRelativePath();
  const userQuery = useGetOGPUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userQuery.error instanceof ExtendedError &&
      userQuery.error.type === ExtendedErrorType.ImposterMissingCompanyId
    ) {
      alertError(userQuery.error);
      navigate(`${hqPrefix}/sales/companies`);
    }
  }, [alertError, userQuery.error, navigate]);

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return (
    <DialogProvider>
      <Routes>
        <Route path="/" element={<Navigate replace to={`${companyBasePath}/planning`} />} />
        {userQuery.data
          ? RouteRenderer(
              Object.values(items),
              companyBasePath,
              userQuery.data.userInfo,
              BaseLayout
            )
          : null}

        <Route
          path={`${companyBasePath}/planning`}
          element={<Navigate replace to={generatePath(items.Shifts)} />}
        />
        <Route
          path={`${companyBasePath}/management`}
          element={<Navigate replace to={generatePath(items.Company)} />}
        />
        <Route
          path={`${companyBasePath}/checkout`}
          element={<Navigate replace to={generatePath(items.CheckoutsInbox)} />}
        />

        <Route path={ogpPrefix} element={<Navigate replace to={generatePath(items.Shifts)} />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </DialogProvider>
  );
};

export { Router };
