import { Box, styled, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Header, LwButton, LwTab, Page, Toolbox } from 'redesign';
import { invariant } from 'shared/utils/utils';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { PlatformSettingsTab } from './tabs/platform-information-tab';
import { MinimumRateTab } from './tabs/minimum-rate-tab';

type PanelTabType = {
  id: React.ComponentProps<typeof Tab>['value'];
  tabLabel: React.ComponentProps<typeof Tab>['label'];
  element: React.ReactNode;
};

export const PlatformSettingsPage = () => {
  const generatePath = useHqRelativePath();
  const tabs: PanelTabType[] = getTabs();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleTabChange = (_: React.SyntheticEvent, tabId: number) => {
    const selectedTab = tabs.find((i) => i.id === tabId);
    invariant(selectedTab, 'Tab not found');
    setSelectedTab(selectedTab);
  };

  return (
    <Page
      header={<Header titleText="Platform instellingen" />}
      toolbox={
        <Toolbox>
          <StyledToolbox>
            <StyledTabs value={selectedTab.id} onChange={handleTabChange} aria-label="tabbladen">
              {tabs.map((tab) => (
                <LwTab key={tab.id} value={tab.id} label={tab.tabLabel} />
              ))}
            </StyledTabs>
            <ButtonWrap>
              {selectedTab.id === 'platform-information' ? (
                <LwButton color="primary" to={generatePath(hqRoutes.PlatformSettingsUpdate)}>
                  Aanpassen
                </LwButton>
              ) : null}
            </ButtonWrap>
          </StyledToolbox>
        </Toolbox>
      }
    >
      <Box width="100%">{selectedTab.element}</Box>
    </Page>
  );
};

const getTabs = () => {
  return [
    {
      id: 'platform-information',
      tabLabel: 'Algemene informatie',
      element: <PlatformSettingsTab />,
    },
    {
      id: 'minimal-wage',
      tabLabel: 'Minimum uurtarief',
      element: <MinimumRateTab />,
    },
  ];
};

const StyledToolbox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& div.MuiTabs-scroller': {
    '& .MuiTabs-flexContainer': {
      gap: theme.spacing(2),
      overflow: 'visible',
    },
  },
}));

const ButtonWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  '& > :last-child': {
    marginRight: theme.spacing(8),
  },
}));
