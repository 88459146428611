import { AccreditationsColumnDefinition } from './accreditations-table-provider.types';
import { RawAccreditationData } from '../../../services/accreditations-service.types';

export const getAccreditationsTableData = (data: RawAccreditationData[]) => {
  const result: AccreditationsColumnDefinition[] = data.map((item) => {
    return {
      flexWorkerId: item.flexWorkerId,
      certificateId: item.certificateId,
      type: item.type,
      flexWorkerName: {
        name: `${item.flexWorker.firstName} ${item.flexWorker.lastName}`,
        hasNotification: item.notification,
      },
      email: item.flexWorker.email,
      certificate: item.certificate.name,
      createdAt: item.createdAt,
      state: item.state,
      startDate: item.startDate,
      expirationDate: item.expirationDate,
      actions: {
        flexWorkerId: item.flexWorkerId,
        certificateId: item.certificateId,
      },
      isThereADocument: item.isThereADocument,
    };
  });
  return result;
};
