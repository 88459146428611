import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { formatFullName } from 'shared/utils/formatting';
import { ApplicantColumnDefinition } from './applicant-table.types';
import { ApplicantDetail, ShiftDetailsApplicant } from '../../applicant-table.types';

export const getApplicantTableData = (
  applicationDetails: ApplicantDetail[],
  shiftDetails: ShiftDetailsApplicant
): ApplicantColumnDefinition[] => {
  return applicationDetails.map((applicationDetail) => ({
    flexWorkerImage: {
      firstName: applicationDetail.firstName,
      lastName: applicationDetail.lastName,
      imageUrl: applicationDetail.imageUrl,
      relation: { id: applicationDetail.applicationId, relation: RelationType.SHIFTAPPLICATION },
      id: applicationDetail.workerId,
      hasCandidateLevel: applicationDetail.hasCandidateLevel,
      employmentType: applicationDetail.employmentType,
    },
    flexWorkerFullName: formatFullName({
      firstName: applicationDetail.firstName,
      lastName: applicationDetail.lastName,
    }),
    rating: applicationDetail.ratingPercentage,
    attendanceRate: applicationDetail.attendanceRate,
    workedShifts: applicationDetail.workedTotal ?? 0,
    hourlyRate: {
      hourlyRate: applicationDetail.hourlyRateCents / 100,
      defaultHourlyRate: shiftDetails.defaultHourlyRate / 100,
      workerEmploymentType: applicationDetail.employmentType,
      hasDivergentRate: applicationDetail.hasDivergentRate,
    },
    hasRequiredCertificates: applicationDetail.hasRequiredCertificates ?? false,
    appliedShifts: applicationDetail.allApplications.length,
    phone: applicationDetail.phone ?? '',
    actions: applicationDetail,
    experienceInMonths: applicationDetail.experienceInMonths,
    distanceInKm: applicationDetail.distanceInKm,
  }));
};
