import { AddAndApproveAccreditationParams } from 'hq/components/views/accreditations/hooks/use-add-and-approve-accreditation';
import {
  addAccreditationFiles,
  addFieldsToFormData,
} from 'shared/utils/file-handling/file-handling-utils';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { HqApiService } from './hqApiService';
import {
  AccreditationDataHQ,
  AccreditationType,
  GetAccreditationsResponse,
  HQCertificateWithFileUrl,
  RawAccreditationData,
} from './accreditations-service.types';
import { AccreditationParams } from '../components/views/accreditations/accreditations.types';

class AccreditationsService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getAccreditations(
    filterParams: AccreditationParams
  ): Promise<GetAccreditationsResponse> {
    const response = await this.hqApiService.get<GetAccreditationsResponse>({
      path: 'accreditations',
      params: Object.fromEntries(Object.entries(filterParams).filter(([, value]) => value !== '')),
    });
    return response;
  }

  public async getCertificates(): Promise<HQCertificateWithFileUrl[]> {
    const response = await this.hqApiService.get<HQCertificateWithFileUrl[]>({
      path: 'accreditations/certificates',
    });
    return response;
  }

  public async getAccreditationsForFlexWorker(
    flexWorkerId: string
  ): Promise<AccreditationDataHQ[]> {
    const response = await this.hqApiService.get<AccreditationDataHQ[]>({
      path: `accreditations/${flexWorkerId}`,
    });
    return response;
  }

  public async getAccreditationDetail(
    certificateId: string,
    flexWorkerId: string,
    accreditationType: AccreditationType
  ): Promise<RawAccreditationData> {
    const response = await this.hqApiService.get<RawAccreditationData>({
      path: `accreditations/${flexWorkerId}/${certificateId}/${accreditationType}`,
    });
    return response;
  }

  public async acceptAccreditation(
    flexWorkerId: string,
    certificateId: string,
    accreditationType: AccreditationType,
    date?: DateTime | null
  ): Promise<void> {
    await this.hqApiService.update<void>({
      body: { expirationDate: date, accreditationType: accreditationType },
      path: `accreditations/${flexWorkerId}/${certificateId}/approve`,
    });
  }

  public async addAndAcceptAccreditation({
    startDate,
    expirationDate,
    // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
    accreditations,
    flexWorkerId,
    accreditationType,
  }: AddAndApproveAccreditationParams): Promise<void> {
    const formData = getWorkerPayloadFormData({
      expirationDate,
      accreditations,
      startDate,
      accreditationType,
    });
    await this.hqApiService.create<void>({
      body: formData,
      path: `accreditations/${flexWorkerId}/add-and-approve`,
    });
  }

  public async updateAccreditation(
    flexWorkerId: string,
    certificateId: string,
    expirationDate: DateTime | null,
    startDate: DateTime | null,
    accreditationType: AccreditationType,
    accreditations?: AccreditationFormRepresentation[]
  ): Promise<void> {
    const formData = getWorkerPayloadFormData({
      expirationDate,
      startDate,
      // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
      accreditations,
      accreditationType,
    });

    await this.hqApiService.update<void>({
      body: formData,
      path: `accreditations/${flexWorkerId}/${certificateId}/update`,
    });
  }

  public async rejectAccreditation(
    flexWorkerId: string,
    certificateId: string,
    accreditationType: AccreditationType
  ): Promise<void> {
    await this.hqApiService.delete<void>({
      body: { accreditationType: accreditationType },
      path: `accreditations/${flexWorkerId}/${certificateId}/reject`,
    });
  }
}

function getWorkerPayloadFormData(body: {
  // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
  accreditations?: AccreditationFormRepresentation[];
  startDate: DateTime | null;
  expirationDate: DateTime | null;
  accreditationType: AccreditationType;
}): FormData {
  const formData = new FormData();

  let accreditations = '';
  if (!isUndefined(body.accreditations)) {
    addAccreditationFiles(formData, body.accreditations);

    accreditations = JSON.stringify(
      body.accreditations?.map((accreditation) => accreditation.id) ?? []
    );
  }

  const bodyWithoutFiles: { [key: string]: string | undefined } = {
    ...(accreditations.length > 0 && { accreditations }),
    expirationDate: body.expirationDate ? body.expirationDate.toFormat('yyyy-MM-dd') : undefined,
    startDate: body.startDate ? body.startDate.toFormat('yyyy-MM-dd') : undefined,
    accreditationType: body.accreditationType,
  };

  addFieldsToFormData(formData, bodyWithoutFiles);

  return formData;
}

export { AccreditationsService };
