import { PartnerApiService } from './partner-api-service';
import {
  GetPartnerPlacementsParams,
  GetPartnerPlacementsResponse,
} from './partner-placements-service.types';

class PartnerPlacementService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public getPartnerPlacements = async (
    params: GetPartnerPlacementsParams
  ): Promise<GetPartnerPlacementsResponse> => {
    return await this.partnerApiService.get<GetPartnerPlacementsResponse>({
      path: 'placements',
      params,
    });
  };

  public cancelShiftPlacement = async (placementId: string): Promise<void> => {
    await this.partnerApiService.delete({
      path: `placements/shift-claim/cancel-placement/${placementId}`,
    });
  };

  public cancelShiftPlacements = async (placementIds: string[]): Promise<void> => {
    await this.partnerApiService.update({
      path: 'placements/shift-claim/cancel-placements',
      body: { placementIds },
    });
  };
}

export { PartnerPlacementService };
