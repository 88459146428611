import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { adminSettingsService } from 'hq/services';
import { MinimumRateForJobTypesResponse } from 'hq/services/admin-settings-service.types';
import { useQuery } from 'react-query';

export const useGetMinimumRateForJobTypes = () => {
  const queryFn = async (): Promise<MinimumRateForJobTypesResponse> => {
    return await adminSettingsService.getMinimumRateForJobTypes();
  };

  return useQuery<MinimumRateForJobTypesResponse, Error, MinimumRateForJobTypesResponse>(
    QUERY_KEYS_HQ.minimumRateForJobTypes(),
    queryFn
  );
};
