import { Box } from '@mui/material';
import { Header, Page } from 'redesign';
import { UploadBusCsvForm } from './bus-csv-upload-form';

const UploadPayments = () => {
  return (
    <Page header={<Header titleText={`Upload CSV Payments`} />}>
      <Box width="100%">
        <UploadBusCsvForm />
      </Box>
    </Page>
  );
};

export { UploadPayments };
