import { Tooltip, Typography } from '@mui/material';

export const CertificatesDetail = (props: { certificates: string[] }) => {
  const { certificates } = props;
  const label = certificates.length === 1 ? 'certificaat' : 'certificaten';
  return (
    <Tooltip title={certificates.length > 0 ? certificates.join(', ') : null}>
      <Typography noWrap variant="subtitle2">
        {certificates.length + ' ' + label}
      </Typography>
    </Tooltip>
  );
};
