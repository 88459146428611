import { Control } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { ListSubheader, MenuItem } from '@mui/material';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const DepartmentFormElement = ({
  control,
  options,
  disabled,
  onChange,
}: {
  control: Control<ProjectFormTypeAll>;
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
  onChange?: () => void;
}) => {
  return (
    <LwFormSelect
      name="departmentId"
      label="Afdeling"
      defaultLabel="Selecteer een afdeling..."
      options={options}
      renderOption={(opt) => {
        return [
          <ListSubheader key={opt.value} value={opt.value}>
            <em>{opt.label}</em>
          </ListSubheader>,
          opt.options.map((dpt) => (
            <MenuItem key={dpt.value} value={dpt.value}>
              {dpt.label}
            </MenuItem>
          )),
        ];
      }}
      disabled={disabled}
      control={control}
      rules={{ required: 'Selecteer een afdeling' }}
      onChange={onChange}
    />
  );
};
