import { AutocompleteOption } from 'redesign';
import {
  ShiftPlanningFiltersJobType,
  ShiftPlanningFiltersPlanner,
} from 'hq/services/shift-planning-service.types';
import { ShiftPlanningFiltersData } from '../shift-planning-filters.types';
import { ShiftPlanningFilters } from '../../table/hooks/use-shift-planning.types';

export const checkBranchOfficesInCompany = (
  branchOfficeNames: string[],
  shiftPlanningFiltersData: ShiftPlanningFiltersData
): boolean => {
  const allBranchOfficeNames =
    shiftPlanningFiltersData.companies
      ?.flatMap((company) => company.branchOffices)
      .map((branchOffice) => branchOffice.branchOfficeName) || [];

  // Check if all branch office names are present
  return branchOfficeNames.every((name) => allBranchOfficeNames.includes(name));
};

export const getPartner = (
  appliedFilters: ShiftPlanningFilters,
  partners: ShiftPlanningFiltersPlanner[]
) => {
  if (!appliedFilters) {
    return undefined;
  }
  const partner = partners.find((databasePartner) => {
    return databasePartner.id === appliedFilters.partner;
  });
  if (partner) {
    return { value: partner.id, label: partner.name };
  }
  return undefined;
};

export const getJobTypes = (
  appliedJobTypes: string[] | undefined,
  jobTypes: ShiftPlanningFiltersJobType[]
): AutocompleteOption[] => {
  const cleanedJobTypes: AutocompleteOption[] = [];
  if (!appliedJobTypes || !appliedJobTypes.length) {
    return [];
  }
  appliedJobTypes.forEach((jobTypeParamId) => {
    const jobType = jobTypes.find((databaseJobType) => {
      return databaseJobType.id === jobTypeParamId;
    });
    if (!jobType) {
      return;
    }

    cleanedJobTypes.push({ value: jobType.id, label: jobType.name } as AutocompleteOption);
  });

  return cleanedJobTypes;
};
