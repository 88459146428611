import { Box } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useGetMinimumRateForJobTypes } from '../hooks/use-get-minimum-rate-for-job-types';
import { MinimumRateTable } from '../components/minimum-rate-table/minimum-rate-table';

export const MinimumRateTab = () => {
  const useGetMinimumRateForJobTypesQuery = useGetMinimumRateForJobTypes();

  if (useGetMinimumRateForJobTypesQuery.status !== 'success') {
    return <RenderUnsettledUI data={useGetMinimumRateForJobTypesQuery} />;
  }

  return (
    <Box minHeight={0}>
      <MinimumRateTable items={useGetMinimumRateForJobTypesQuery.data.items} />
    </Box>
  );
};
