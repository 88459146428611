import { useGetOGPUserInfo } from 'ogp/hooks';
import { useFormContext } from 'react-hook-form';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { useOgpGetShiftCreateData } from '../hooks/ogp/use-ogp-get-shift-create-data';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { ShiftFormProps } from '../components/forms/shared/types';
import { OGPShiftFormData } from '../types/shift-forms.types';
import { useGetJob } from '../../../hooks/query-hooks/use-get-job';

export const OgpShiftForm = ({
  mode,
  workDetails,
}: Pick<ShiftFormProps, 'mode' | 'workDetails'>) => {
  const { watch } = useFormContext<OGPShiftFormData>();
  const { jobId } = watch();
  const shiftCreateDataQuery = useOgpGetShiftCreateData();
  const selectedJobQuery = useGetJob(jobId);
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes();
  const { data: ogpUserInfo } = useGetOGPUserInfo();

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }

  if (shiftCreateDataQuery.status === 'error') {
    return <RenderUnsettledUI data={shiftCreateDataQuery} />;
  }

  const disabled = mode === 'read' || shiftCreateDataQuery.status === 'loading';
  const isHqAdmin =
    !!ogpUserInfo &&
    hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, ogpUserInfo.userInfo, CompanyRole.HQADMIN);
  const allowDateInThePast = isHqAdmin || mode === 'create' || mode === 'duplicate';

  return (
    <ShiftForm
      mode={mode}
      isDisabled={disabled}
      shiftCreateData={
        shiftCreateDataQuery.data ?? { branchOfficesWithDepartments: [], breakOptions: [] }
      }
      jobData={selectedJobQuery.data}
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      allowDateBeforeToday={allowDateInThePast}
      workDetails={workDetails}
    />
  );
};
