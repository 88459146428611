import { PartnerApiService } from './partner-api-service';
import {
  BusinessUnitFilterParams,
  JobTypeFilterParams,
  SharedFiltersBusinessUnitsData,
  SharedFiltersJobTypesData,
  SharedFiltersWorkerData,
} from './partner-shared-service.types';

export class PartnerSharedService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public async getFilterBusinessUnitsData(params: BusinessUnitFilterParams) {
    const response = await this.partnerApiService.get<SharedFiltersBusinessUnitsData>({
      params,
      path: 'shared/business-units',
    });

    return response;
  }

  public async getFilterJobTypesData(params: JobTypeFilterParams) {
    const response = await this.partnerApiService.get<SharedFiltersJobTypesData>({
      params,
      path: 'shared/job-types',
    });

    return response;
  }

  public async getFilterWorkersData() {
    const response = await this.partnerApiService.get<SharedFiltersWorkerData[]>({
      path: 'shared/workers',
    });

    return response;
  }
}
