import { FirstParamOfFn } from '@types';
import { partnerPlannerService } from '../services';
import { PartnerPortalCheckoutsParams } from '../services/partner-checkouts-service.types';
import { GetPartnerCheckedOutParams } from '../services/checked-outs-service';
import { GetPartnerPlacementsParams } from '../services/partner-placements-service.types';

export const QUERY_KEYS_PARTNER = {
  allPartner: () => ['partner'],

  // Users data
  users: () => [...QUERY_KEYS_PARTNER.allPartner(), 'users'],
  userList: () => [...QUERY_KEYS_PARTNER.users(), 'list'],
  user: (userId: string) => [...QUERY_KEYS_PARTNER.users(), { userId }],
  userInfo: () => [...QUERY_KEYS_PARTNER.users(), 'user-info'],

  // Planner Page
  shiftPlanning: () => [...QUERY_KEYS_PARTNER.allPartner(), 'shift-planning'],
  shiftPlanningWithFilters: (
    params: FirstParamOfFn<typeof partnerPlannerService.getShiftPlanningShifts>
  ) => [...QUERY_KEYS_PARTNER.shiftPlanning(), params, 'shift-planning-with-filters'],

  // Shift detail
  shiftPlanningShiftDetail: (shiftId: string | undefined) => [
    ...QUERY_KEYS_PARTNER.shiftPlanning(),
    shiftId,
  ],
  shiftPlanningShiftWorkers: (shiftId: string | undefined, fullNameSearch?: string) => [
    ...QUERY_KEYS_PARTNER.shiftPlanningShiftDetail(shiftId),
    'workers',
    { fullNameSearch },
  ],
  shiftPlanningShiftClaimants: (shiftId: string | undefined) => [
    ...QUERY_KEYS_PARTNER.shiftPlanningShiftDetail(shiftId),
    'claimants',
  ],

  // Workers
  partnerWorkers: () => [...QUERY_KEYS_PARTNER.allPartner(), 'workers'],
  partnerWorkersFiltered: (filters?: string) => [...QUERY_KEYS_PARTNER.partnerWorkers(), filters],
  partnerWorker: (workerId: string) => [
    ...QUERY_KEYS_PARTNER.partnerWorkersFiltered(),
    { workerId },
  ],

  // Placements
  placements: () => [...QUERY_KEYS_PARTNER.allPartner(), 'placements'],
  placementsWithFilters: (params: GetPartnerPlacementsParams) => [
    ...QUERY_KEYS_PARTNER.placements(),
    params,
    'placements-with-filters',
  ],

  // inbox
  partnerInboxMessages: () => [...QUERY_KEYS_PARTNER.allPartner(), 'inbox'],
  partnerInboxMessage: (messageId: string) => [
    ...QUERY_KEYS_PARTNER.allPartner(),
    'inbox',
    { messageId },
  ],
  partners: () => [...QUERY_KEYS_PARTNER.allPartner(), 'partners'],

  // ShiftClaims data
  shiftClaims: (filters?: GetPartnerCheckedOutParams) => [
    ...QUERY_KEYS_PARTNER.allPartner(),
    'shift-claims',
    filters,
  ],

  // Checkouts
  checkouts: (filters?: PartnerPortalCheckoutsParams) => [
    ...QUERY_KEYS_PARTNER.allPartner(),
    'checkouts',
    filters,
  ],
  checkout: (shiftClaimId: string) => [
    ...QUERY_KEYS_PARTNER.checkouts(),
    'checkout',
    { shiftClaimId },
  ],
  counterOffers: (filters?: PartnerPortalCheckoutsParams) => [
    ...QUERY_KEYS_PARTNER.allPartner(),
    'counterOffers',
    filters,
  ],
  counterOffer: (shiftClaimId: string) => [
    ...QUERY_KEYS_PARTNER.counterOffers(),
    'counterOffer',
    { shiftClaimId },
  ],
  checkoutsMetaData: () => [...QUERY_KEYS_PARTNER.allPartner(), 'checkoutsMetaData'],
  breakOptions: () => [...QUERY_KEYS_PARTNER.allPartner(), 'breakOptions'],

  checkoutInboxFilters: () => [...QUERY_KEYS_PARTNER.allPartner(), 'checkout-inbox-filters'],

  // Accreditations
  certificates: () => [...QUERY_KEYS_PARTNER.allPartner(), 'certificates'],

  // Notifications
  notifications: () => [...QUERY_KEYS_PARTNER.allPartner(), 'notifications'],

  // Shared
  filterBusinessUnitsData: () => [...QUERY_KEYS_PARTNER.allPartner(), 'filters-business-units'],
  filterPlanningJobTypesData: () => [...QUERY_KEYS_PARTNER.allPartner(), 'filters-job-types'],
  filterWorkersData: () => [...QUERY_KEYS_PARTNER.allPartner(), 'filters-workers'],
};
