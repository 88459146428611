import { useEffect, useMemo } from 'react';
import {
  AutocompleteOption,
  FileUpload,
  FormCheckbox,
  LwFormDate,
  LwFormSelect,
  UploadedFileList,
} from 'redesign';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { HQCertificateWithFileUrl } from 'hq/services/accreditations-service.types';
import { WorkerList } from 'hq/services/flex-worker-service.types';
import { Section } from 'shared/components/common/form-section';
import { IconInfoRedesigned } from 'assets/img';
import { accreditationTypeOptions } from './utils';
import { FlexWorkerSelect, FlexWorkerSelectStateProps } from '../../flexWorkers/flex-worker-select';
import { State } from '../accreditations.types';

export type AccreditationFormTypes = {
  // TODO refactor this. "accreditations" is the wrong name and is confusing, these are just files with a certificate id. https://levelworks.atlassian.net/browse/LW1-17607
  files?: File[];
  accreditationType: string | null;
  certificate: string | null;
  startDate: string | null;
  isWithoutDocument: boolean;
  expirationDate: string | null;
  flexWorkerOption: AutocompleteOption | null;
  hasPreviouslyUploadedDocument: boolean;
  accreditationState: State;
};

type AccreditationFormProps = FlexWorkerSelectStateProps & {
  certificates: HQCertificateWithFileUrl[];
  flexWorkers: WorkerList[];
  formState: 'create' | 'update';
};

export const AccreditationFormHQ = (props: AccreditationFormProps) => {
  const formMethods = useFormContext<AccreditationFormTypes>();

  const {
    isWithoutDocument,
    accreditationType,
    hasPreviouslyUploadedDocument,
    accreditationState,
    certificate,
  } = formMethods.watch();

  const filteredCertificates = useMemo(() => {
    if (accreditationType !== 'receipt') {
      return props.certificates;
    }
    return props.certificates.filter((certificate) => certificate.canHaveReceipt === true);
  }, [accreditationType, props.certificates]);

  const clearFiles = () => {
    formMethods.setValue('files', []);
  };

  useEffect(() => {
    if (props.formState === 'update' && hasPreviouslyUploadedDocument) {
      const existingFile = { name: certificate };
      formMethods.setValue('files', [existingFile as File], { shouldDirty: true });
    }
  }, [props.formState, hasPreviouslyUploadedDocument]);

  return (
    <Box width={'100%'}>
      <Grid container>
        {props.formState === 'update' ? (
          <Section sectionLabel="Status">
            <Grid item xs={4}>
              <AccreditationState variant="body1">
                Huidige staat: {accreditationState}
              </AccreditationState>
            </Grid>
          </Section>
        ) : null}
        <Section sectionLabel="Kies een flexwerker">
          <Grid item xs={4}>
            <FlexWorkerSelect
              workers={props.flexWorkers}
              // TODO create an own provider and not pass these props LW1-16844
              flexWorkerOption={props.flexWorkerOption}
              flexWorkerSearch={props.flexWorkerSearch}
              setFlexWorkerSearch={props.setFlexWorkerSearch}
              setFlexWorkerOption={props.setFlexWorkerOption}
              disabled={props.formState === 'update'}
            />
          </Grid>
        </Section>
        <Section sectionLabel="Selecteer certificaat">
          <Grid item xs={4}>
            <Box display="flex" flex={1}>
              <LwFormSelect
                control={formMethods.control}
                name="accreditationType"
                label="Type"
                defaultLabel="Selecteer een accreditatietype"
                options={accreditationTypeOptions}
                onChange={(e) => {
                  formMethods.setValue('accreditationType', e.target.value);
                }}
                disabled={props.formState === 'update'}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <LwFormSelect
              control={formMethods.control}
              name="certificate"
              label="Certificaten"
              options={filteredCertificates.map((certificate) => ({
                value: certificate.id,
                label: certificate.name,
              }))}
              rules={{ required: true }}
            ></LwFormSelect>
          </Grid>

          <Grid item xs={4}>
            <FormCheckbox
              name="isWithoutDocument"
              label="Een certificaat aanmaken zonder certificaat document"
              control={formMethods.control}
              disabled={hasPreviouslyUploadedDocument}
              onChange={(e) => {
                e === true ? clearFiles() : null;
                formMethods.setValue('startDate', null);
              }}
            />
            <Box display="flex" alignItems="center" gap={4} mt={2}>
              <IconInfoRedesigned width={24} height={24} /> Je moet een startdatum instellen en het
              certificaat document uploaden na de startdatum
            </Box>
          </Grid>
          <Grid container item xs={4} spacing={4}>
            <Grid item xs={6}>
              <LwFormDate
                name="startDate"
                label="Startdatum"
                control={formMethods.control}
                minDate={DateTime.now().toISODate()}
                rules={{ required: isWithoutDocument ? 'Voer een startdatum in' : false }}
                disabled={!isWithoutDocument}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormDate
                name="expirationDate"
                label="Vervaldatum"
                control={formMethods.control}
                minDate={DateTime.now().toISODate()}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={formMethods.control}
              name="files"
              defaultValue={[]}
              render={({ field: { value, onChange } }) => (
                <>
                  <FileUpload disableUplad={isWithoutDocument} onFilesChange={onChange} />
                  <UploadedFileList files={value} onClearFiles={() => onChange([])} />
                </>
              )}
            />
          </Grid>
        </Section>
      </Grid>
    </Box>
  );
};

const AccreditationState = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));
