import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { jobService as jobServiceHQ } from 'hq/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService as jobServiceOGP } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { GetJobDetailsForWorkTemplateOGP } from 'ogp/services/job-service.types';
import { GetJobDetailsForWorkTemplateHQ } from 'hq/services/job-service.types';

export const useGetJobDetails = (jobId: string) => {
  const { isHQ } = useSiteDetection();

  const queryFn = getQueryFn(isHQ);
  const queryKey = getQueryKey(isHQ, jobId);

  return useQuery<
    Promise<GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP>,
    Error,
    GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP
  >(queryKey, () => queryFn(jobId), {
    enabled: !!jobId,
  });
};

const getQueryFn = (
  isHQ: boolean
): ((id: string) => Promise<GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP>) => {
  return isHQ
    ? async (id: string) => await jobServiceHQ.getJobDetailsForWorkTemplate(id)
    : async (id: string) => await jobServiceOGP.getJobDetailsForWorkTemplate(id);
};

const getQueryKey = (isHQ: boolean, id: string) => {
  if (isHQ) {
    return QUERY_KEYS_HQ.jobDetails(id);
  }
  return QUERY_KEYS_OGP.jobDetails(id);
};
