import type { Column } from 'react-table';
import SortIcon from 'assets/img/icons/caret-down.svg?react';

import { Box, Button, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ContextMenuButton, LevelTableHeaderProps } from 'redesign';
import { translateState } from './accreditations.types';
import {
  AccreditationCellProp,
  AccreditationsColumnDefinition,
  AccreditationTableDefinition,
} from './accreditations-table-provider.types';

export const getAccreditationsColumnDefinition = (): Column<AccreditationsColumnDefinition>[] => {
  const flexWorkerName: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Naam flexwerker</Header>,
    accessor: 'flexWorkerName',
    Cell: FlexWorkerNameCell,
  };
  const certificate: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Certificaat</Header>,
    accessor: 'certificate',
    Cell: CertificateCell,
    width: 560,
  };
  const certificateType: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Certificaattype</Header>,
    accessor: 'type',
    Cell: CertificateTypeCell,
    width: 140,
  };
  const createdAt: Column<AccreditationsColumnDefinition> = {
    Header: SortableDateHeaderCell,
    accessor: 'createdAt',
    Cell: DateCell,
    width: 220,
  };

  const startDate: Column<AccreditationsColumnDefinition> = {
    Header: SortableDateHeaderCell,
    accessor: 'startDate',
    Cell: DateCell,
    width: 220,
  };

  const endDate: Column<AccreditationsColumnDefinition> = {
    Header: SortableDateHeaderCell,
    accessor: 'expirationDate',
    Cell: DateCell,
    width: 220,
  };

  const state: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Status</Header>,
    accessor: 'state',
    Cell: StateCell,
    width: 120,
  };

  const isThereADocument: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Document</Header>,
    accessor: 'isThereADocument',
    Cell: IsThereADocumentCell,
    width: 120,
  };

  const actions: Column<AccreditationsColumnDefinition> = {
    Header: () => <Header>Actie</Header>,
    accessor: 'actions',
    Cell: ActionCell,
    width: 80,
  };

  return [
    flexWorkerName,
    certificate,
    certificateType,
    state,
    isThereADocument,
    startDate,
    endDate,
    createdAt,
    actions,
  ];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const SortableDateHeaderCell = (
  cell: LevelTableHeaderProps<AccreditationsColumnDefinition, AccreditationTableDefinition>
) => {
  if (!cell.data[0]) {
    return null;
  }

  let headerText = '';

  switch (cell.column.id) {
    case 'startDate':
      headerText = 'Startdatum';
      break;
    case 'expirationDate':
      headerText = 'Einddatum';
      break;
    case 'createdAt':
      headerText = 'Datum van toevoeging';
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown column id in SortableDateHeaderCell', cell.column.id);
  }

  const SelectedComponent =
    cell.orderBy === cell.column.id ? StyledSortIconSelected : StyledSortIcon;

  const transformData =
    cell.orderBy === cell.column.id && cell.sortState === 'asc' ? 'rotate(180deg)' : '';

  return (
    <Button
      variant="text"
      onClick={() => {
        cell.onSortClick(cell);
      }}
    >
      <Header>{headerText}</Header>
      <SelectedComponent
        style={{
          transform: transformData,
        }}
      />
    </Button>
  );
};

const FlexWorkerNameCell = (cell: AccreditationCellProp<'flexWorkerName'>) => {
  return (
    <Box display="flex" alignItems="center">
      {cell.value.hasNotification ? (
        <RedDotNotification data-testid="accreditation-notification" />
      ) : null}
      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
        {cell.value.name}
      </Typography>
    </Box>
  );
};

const CertificateCell = (cell: AccreditationCellProp<'certificate'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const CertificateTypeCell = (cell: AccreditationCellProp<'type'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const StateCell = (cell: AccreditationCellProp<'state'>) => {
  return <Typography variant="subtitle2">{translateState(cell.value)}</Typography>;
};

const DateCell = (
  cell:
    | AccreditationCellProp<'createdAt'>
    | AccreditationCellProp<'startDate'>
    | AccreditationCellProp<'expirationDate'>
) => {
  if (!cell.value) {
    return null;
  }
  const date = DateTime.fromISO(cell.value).setLocale('nl');
  return (
    <Typography variant="subtitle2" style={{ marginLeft: '32px' }}>
      {date.toFormat('ccc, dd-LL-yy')}
    </Typography>
  );
};

const ActionCell = (cell: AccreditationCellProp<'actions'>) => {
  const actions = cell.getRowActions(
    cell.value.flexWorkerId,
    cell.value.certificateId,
    cell.row.original.type
  );

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`accreditation-actions-menu-${cell.value.certificateId}`}>
      {actions}
    </ContextMenuButton>
  );
};

const IsThereADocumentCell = (cell: AccreditationCellProp<'isThereADocument'>) => {
  return (
    <Typography variant="subtitle2" style={{ marginLeft: '32px' }}>
      {cell.value ? 'Ja' : 'Nee'}
    </Typography>
  );
};

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));

const StyledSortIconSelected = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  transition: 'ease transform .5s',
  height: '16px',
  width: '16px',
  padding: '1px',
  border: `1px solid ${theme.palette.lwBlack[60]}`,
  borderRadius: '50%',
  boxSizing: 'border-box',
}));

const RedDotNotification = styled(Box)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: theme.palette.lwDanger[100],
  marginBottom: theme.spacing(8),
  marginRight: theme.spacing(1),
}));
