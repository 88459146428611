import { workService as workServiceHQ } from 'hq/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { workService as workServiceOGP } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { GetWorkDetailsForWorkTemplateHQ } from 'hq/services/work-service.types';
import { GetWorkDetailsForWorkTemplateOGP } from 'ogp/services/work-service.types';

export const useGetWorkDetails = (workId: string) => {
  const { isHQ } = useSiteDetection();

  const queryFn = getQueryFn(isHQ);
  const queryKey = getQueryKey(isHQ, workId);

  return useQuery<
    Promise<GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP>,
    Error,
    GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP
  >(queryKey, () => queryFn(workId), {
    enabled: !!workId,
  });
};

const getQueryFn = (
  isHQ: boolean
): ((
  id: string
) => Promise<GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP>) => {
  return isHQ
    ? async (id: string) => await workServiceHQ.getWorkDetailsForWorkTemplate(id)
    : async (id: string) => await workServiceOGP.getWorkDetailsForWorkTemplate(id);
};

const getQueryKey = (isHQ: boolean, id: string) => {
  if (isHQ) {
    return QUERY_KEYS_HQ.workDetails(id);
  }
  return QUERY_KEYS_OGP.workDetails(id);
};
