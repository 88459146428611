import { Box, Grid, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useGetPlatformSettings } from '../hooks/use-get-platform-settings';

export const PlatformSettingsTab = () => {
  const settingsQuery = useGetPlatformSettings();

  if (settingsQuery.status !== 'success') {
    return <RenderUnsettledUI data={settingsQuery} />;
  }

  const { data } = settingsQuery;

  return (
    <Box mt={4}>
      <Grid item container>
        <Grid item md={4} xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Bedrijfsnaam</Typography>
              <Typography variant="body2">{data.companyName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Adres</Typography>
              <Typography variant="body2">{data.location.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">KVK-nummer</Typography>
              <Typography variant="body2">{data.cocNumber}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Btw-identificatienummer</Typography>
              <Typography variant="body2">{data.vatNumber}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Rentevergoeding early payout</Typography>
              <Typography variant="body2">{data.factoringFee.toFixed(2)}%</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Rekeningnummer</Typography>
              <Typography variant="body2">{data.bankAccountNumber}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Naam rekeninghouder</Typography>
              <Typography variant="body2">{data.bankAccountAscription}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">No-show borg (€)</Typography>
              <Typography variant="body2">{data.noShowBailCents / 100}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Minimum leeftijd uitzendkracht</Typography>
              <Typography variant="body2">{data.minimumTempWorkerAge}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
