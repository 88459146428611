import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerPlacementService } from 'partner/shared/services';
import { GetPartnerPlacementsParams } from 'partner/shared/services/partner-placements-service.types';
import { useInfiniteQuery } from 'react-query';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { PlacementsFilters } from './use-placements-page.types';
import { INITIAL_PAGE } from '../partner-placements.types';

const useGetPartnerPlacements = ({ fromDate, ...filters }: PlacementsFilters) => {
  const params: GetPartnerPlacementsParams = {
    fromDate: fromDate ? fromDate.toISO() : undefined,
    companyId: filters.company,
    branchOfficeId: filters.branchOffice,
    departmentId: filters.department,
    jobTypeIds: filters.jobType,
    workerId: filters.worker,
    isFreelance: filters.isFreelance,
    isTempWork: filters.isTempWork,
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.placementsWithFilters(params),
    queryFn: async () => await partnerPlacementService.getPartnerPlacements(params),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetPartnerPlacements };
