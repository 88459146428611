import {
  BranchOfficeNameListItem,
  BreakOption,
  LocationAutoSuggestItem,
  LocationData,
  VAT,
} from '@types';

type BaseJobDetails = {
  name: string;
  descriptionShort: string;
  descriptionLawRequirements: string;
  descriptionLong?: string;
  contactName: string;
  contactPhoneNumber: string;
  location: LocationData;
  locationUrl: string;
  departmentId: string;
  breakMinutes: number;
  imageUrl: string | null;
  VAT: VAT;
  flexPoolOnly: boolean;
  costCenter: string | null;
  jobTypeId: string;
  ortApplicable: boolean;
  flexPools: { id: string; name: string; directClaimAllowed: boolean }[];
  freelanceJob?: FreelanceJobDetails;
  tempWorkJob?: TempWorkJobDetails;
  requiredCertificates: Certificate[];
  jobCertificates: {
    id: string;
    name: string;
  }[];
};

type Certificate = {
  id: string;
  name: string;
};

type FreelanceJobDetails = {
  hourlyRateCents: number;
  mutableUntilMinutes: number;
};

type TempWorkJobDetails = {
  grossHourlyRateCents: number;
  cla: string;
};

type JobDetailWithId = {
  id: string;
  jobTypeId?: string;
};

export type FreelanceJobDetailsWithId = BaseJobDetails & {
  freelanceJob: FreelanceJobDetails;
  jobRequirements: JobRequirementsFormData;
} & JobDetailWithId;
export type TempWorkJobDetailsWithId = BaseJobDetails & {
  tempWorkJob: TempWorkJobDetails;
  jobRequirements: JobRequirementsFormData;
} & JobDetailWithId;
export type MultipleWorkJobDetailsWithId = BaseJobDetails & {
  jobRequirements: JobRequirementsFormData;
  freelanceJob: FreelanceJobDetails;
  tempWorkJob: TempWorkJobDetails;
};

export function isFreelanceJob(
  job: BaseJobDetails
): job is BaseJobDetails & { freelanceJob: FreelanceJobDetails } {
  return job.freelanceJob != null;
}

export function isTempWorkJob(
  job: BaseJobDetails
): job is BaseJobDetails & { tempWorkJob: TempWorkJobDetails } {
  return job.tempWorkJob != null;
}

export type BaseJobFormData = Omit<
  BaseJobDetails,
  'hourlyRateCents' | 'location' | 'freelanceJob' | 'tempworkJob'
> & {
  // Init as LocationData, will change to AutoSuggestItem when the user types in the field
  location: LocationData | LocationAutoSuggestItem;
};

type FreelanceJobProperties = {
  hourlyRate: number;
  mutableUntilHours: number;
  hourlyRateCents?: number;
  mutableUntilMinutes?: number;
};

export type FreelanceJobFormData = BaseJobFormData &
  FreelanceJobProperties & {
    isTempwork: false;
    isFreelance: true;
    jobRequirements: JobRequirementsFormData;
  };

type TempworkJobProperties = {
  grossHourlyRateCents?: number;
  cla: string;
};

export type JobRequirementsFormData = {
  skills?: { id: string; name: string; type: SkillType }[];
  language?: { id: string; name: string; type: SkillType }[];
  apparel?: { id: string; name: string; type: SkillType }[];
};

export type TempWorkJobFormData = BaseJobFormData &
  TempworkJobProperties & {
    isTempwork: true;
    isFreelance: false;
    jobRequirements: JobRequirementsFormData;
  };

export type MultipleJobFormData = BaseJobFormData &
  FreelanceJobProperties &
  TempworkJobProperties & {
    isTempwork: true;
    isFreelance: true;
    jobRequirements: JobRequirementsFormData;
  };

export type AllJobFormData = FreelanceJobFormData | TempWorkJobFormData | MultipleJobFormData;

export type BaseJobData<T extends BaseJobDetails = BaseJobDetails> = Omit<
  T,
  'location' | 'jobCertificates' | 'flexPools'
> & {
  location: LocationData | LocationAutoSuggestItem;
  jobCertificateIds: string[];
  flexPoolIds: string[];
  jobRequirements: { id: string; name: string; type: SkillType }[];
};

// TODO: Update with actual typing from backend
export type UpdateJobMutationData = {
  id: string;
} & JobPayload;

export type JobPayload = {
  name: string;
  descriptionShort: string;
  descriptionLong?: string | undefined;
  descriptionLawRequirements: string;
  contactName: string;
  contactPhoneNumber: string;
  location: LocationData | LocationAutoSuggestItem;
  locationUrl: string;
  breakMinutes: number;
  imageUrl?: string | null;
  costCenter?: string | null;
  VAT: VAT;
  flexPoolOnly: boolean | undefined;
  ortApplicable?: boolean | undefined;
  jobTypeId: string;
  freelanceJob?: {
    hourlyRateCents: number;
    mutableUntilMinutes: number;
  } | null;
  tempWorkJob?: {
    cla: string;
  } | null;
  jobCertificateIds: string[];
  jobRequirements: {
    id: string;
    name: string;
  }[];
  flexPools: {
    id: string;
    directClaimAllowed: boolean;
  }[];
};

export type JobListItem = {
  id: string;
  flexPoolOnly: boolean;
  name: string;
  departmentName: string;
  companyName: string;
  companyId: string;
  departmentId: string;
  isFreelance: boolean;
  isTempwork: boolean;
};

export type JobCreateData = {
  branchOfficeNames: BranchOfficeNameListItem[];
  breakOptions: BreakOption[];
  jobCertificates: {
    id: string;
    name: string;
  }[];
  jobGroups: {
    id: string;
    name: string;
    jobType: {
      id: string;
      name: string;
    }[];
  }[];
};

export enum SkillType {
  SOFT_SKILL = 'SOFT_SKILL',
  APPAREL = 'APPAREL',
  LANGUAGE = 'LANGUAGE',
}
