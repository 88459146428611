import { Expense } from '@types';
import { LevelTableCellProps } from 'redesign';
import { ShiftClaimState } from 'shared/utils/shift-claim-state.types';

export const INITIAL_PAGE = 0;

type GetPartnerCheckedOutsResponseWithParsedTimes = {
  id: string;
  flexWorkerName: string;
  jobTitle: string;
  companyName: string;
  branchOfficeName: string;
  status: Extract<ShiftClaimState, 'checkoutSettled' | 'checkoutPending'>;
  shiftStartDate: string;
  expense?: Expense[] | null;
  scheduledDateTime: {
    fromDateTime: string;
    toDateTime: string;
    breakMins: number;
  };
  workedDateTime: {
    fromDateTime: string;
    toDateTime: string;
    breakMins: number;
  };
};

export type PartnerCheckedOutColumnDefinition = GetPartnerCheckedOutsResponseWithParsedTimes;

export type PartnerCheckedOutCellProp<T extends keyof PartnerCheckedOutColumnDefinition> =
  LevelTableCellProps<PartnerCheckedOutColumnDefinition, PartnerCheckedOutColumnDefinition[T]>;
