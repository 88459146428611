import { LocationData, SkillType } from '@types';
import { WeekDays } from '../../shared/utils/date-utils';
import {
  AvailabilityStates,
  FlexWorkerPopup,
} from '../../shared/components/shift-detail-modal/components/tabs/shared/types/shift-detail-tables.types';

export type FlexWorker = {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  certificates?: string;
};

export type FlexWorkerName = {
  id: string;
  fullName: string;
};

export type Level = 'EXPLORER' | 'STARTER' | 'CANDIDATE' | 'WORKER' | null;

export type WorkerEmploymentType = 'FREELANCER' | 'TEMPWORKER';

export type HQGetFlexWorkerDetailsResponse = {
  id: string;
  level: Level;
  email: string;
  firstName: string | null;
  lastName: string | null;
  infix?: string;
  phone: string | null;
  dateOfBirth?: string;
  bsn: string | null;
  companyName?: string;
  vatNumber?: string | null;
  cocNumber?: string;
  smallBusinessSchemeStartDate?: string;
  smallBusinessSchemeEndDate?: string;
  bankAccountNumber?: string;
  bankAccountAscription?: string;
  location?: DisplayLocation | null;
  companyLocation?: DisplayLocation | null;
  hubspotContactId?: boolean;
  profilePictureUrl?: string;
  bsnProvided: boolean;
  bioProvided: boolean;
  bio?: string | null;
  hasPendingIdentityVerification: boolean;
  disabled: boolean;
  verified: boolean;
  employmentType: WorkerEmploymentType;
  workedShifts: number;
  paydayOnboardingStatus: string | null;
  employmentId?: string | null;
  sectors: Sector[] | undefined;
  contractStartDate: string | null;
  contractEndDate: string | null;
};

export type ScheduleSegment = {
  startTime: string;
  endTime: string;
  type: 'GENERAL_AVAILABILITY';
  id: string;
};

export type FlexWorkerSchedule = {
  schedule: Record<WeekDays, ScheduleSegment[]>;
};

type DisplayLocation = Pick<
  LocationData,
  'address' | 'city' | 'country' | 'houseNumber' | 'postalCode' | 'street'
>;

export type FlexWorkerDataEdit = Partial<
  Pick<
    HQGetFlexWorkerDetailsResponse,
    | 'firstName'
    | 'lastName'
    | 'infix'
    | 'smallBusinessSchemeStartDate'
    | 'smallBusinessSchemeEndDate'
    | 'bsn'
    | 'dateOfBirth'
    | 'level'
    | 'employmentId'
    | 'contractStartDate'
    | 'contractEndDate'
  >
>;

export type FlexWorkerProfileData = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  id: string;
  workedShifts: number;
  rating?: number;
  attendanceRate?: number;
  imageUrl?: string;
  bio?: string;
  city?: string;
  qualifications: ProfileQualification[];
  workExperiences: WorkExperience[];
  partnerName?: string;
  employmentType: WorkerEmploymentType;
};

export type WorkExperience = {
  companyName: string;
  jobType: string;
  isoStartDateTime: string;
  isoEndDateTime: string;
  description: string;
};

export type ProfileQualification = {
  category: SkillType;
  label: string;
  skills: string[];
};

export type Sector = {
  name: string;
  id: string;
};

export type FlexWorkerProfileHQ = {
  firstName?: string;
  profilePictureUrl?: string;
  bio?: string;
  calculatedRating?: number;
  attendanceRate?: number;
  workedShifts: number;
  sectors: Sector[];
  qualifications: ProfileQualification[];
  workExperiences: WorkExperience[];
};

export type FlexWorkerListItem = {
  id: string;
  email: string;
  firstName: string;
  infix?: string;
  lastName: string;
  hasPendingIdentityVerification: boolean;
  level: Level;
  employmentType: WorkerEmploymentType;
  disabled: boolean;
  phone: string;
  profilePictureUrl?: string;
};

export type FlexWorkersFilters = {
  employmentType: 'FREELANCER' | 'TEMPWORKER' | 'ALL';
  level: 'EXPLORER' | 'STARTER' | 'WORKER' | 'CANDIDATE' | 'INCOMPLETE' | 'ALL';
  filter?: string;
  disabled: boolean;
  page: number;
  size: number;
};

export type FlexWorkerFilterCounts = {
  incompleteCount: number;
  explorerCount: number;
  starterCount: number;
  workerCount: number;
  candidateCount: number;
  disabledCount: number;
};

export type FlexWorkerFlexPools = {
  flexPoolName: string;
  flexPoolId: string;
  flexWorkerId: string;
  isMember: boolean;
};

export enum IDDocumentType {
  ID = 'ID',
  PASSPORT = 'PASSPORT',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
}

export type FlexWorkerIdCheckDetail = {
  id: string;
  selfieImage: string;
  portraitImage: string;
  idDocumentImage?: string;
  firstName: string;
  lastName: string;
  bsn: string | null;
  dateOfBirth: string;
  vatNumber: string | undefined;
  documentType: IDDocumentType;
  documentNumber: string;
  issuanceDate: string;
  expirationDate: string;
};

export type ApproveIdCheck =
  | {
      bsn: string | undefined;
      firstName: string;
      lastName: string;
      documentType: IDDocumentType;
      documentNumber: string;
      issuanceDate: string;
      expirationDate: string;
      dateOfBirth: string;
    }
  | undefined;

export type WorkerForWork = {
  flexWorkerId: string;
  flexWorkerImage: FlexWorkerPopup;
  email: string;
  phoneNumber: string;
  inFlexPool: boolean;
  flexWorkerFullName: string;
  address: string;
  hasRequiredCertificates: boolean;
  distance: number | undefined;
  availability: AvailabilityStates;
  experienceInMonths: number;
  employmentType: WorkerEmploymentType;
};
