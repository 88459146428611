import { RenderUnsettledUI } from 'shared/components';
import { ShiftPlanningFiltersDataProviderProps } from './shift-planning-filters.types';
import { ShiftPlanningFilters } from './shift-planning-filters';
import {
  useGetFilterBusinessUnitsData,
  useGetFilterJobTypesData,
} from '../../../../hooks/use-get-filters-data';

export const ShiftPlanningDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: ShiftPlanningFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetFilterBusinessUnitsData({
    jobTypeIds: appliedFilters.jobType,
  });

  const jobTypeQuery = useGetFilterJobTypesData({
    companyId: appliedFilters.company,
    branchOfficeId: appliedFilters.branchOffice,
    departmentId: appliedFilters.department,
  });

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ShiftPlanningFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
      }}
    />
  );
};
