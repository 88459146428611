import React, { useCallback, useMemo } from 'react';
import { Drawer } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { DateTime } from 'luxon';
import { FilterEmploymentType } from 'shared/components/filters/inputs/employment-type/filter-employment-type';
import { getShiftPlanningFiltersDefaultValues } from './utils/get-shift-planning-default-values';
import {
  ShiftPlanningFiltersFormType,
  ShiftPlanningFiltersProps,
} from './shift-planning-filters.types';
import { FilterWrapper } from '../../../../../../shared/components/filters/filter-wrapper';
import { FilterFromDate } from '../../../../../../shared/components/filters/inputs/date/filter-from-date';
import { FilterBusinessUnitsWithCompany } from '../../../../../../shared/components/filters/inputs/business-units-with-company/filter-business-units-with-company';
import { FilterApplicantStatus } from '../../../../../../shared/components/filters/inputs/filter-applicant-status/filter-applicant-status';
import { FilterFilledStatus } from '../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status';
import { FilterJobTypes } from '../../../../../../shared/components/filters/inputs/job-types/filter-job-types';
import { FilterPartners } from '../../../../../../shared/components/filters/inputs/partners/filter-partners';

export const ShiftPlanningFilters = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
  filterData,
}: ShiftPlanningFiltersProps) => {
  const defaultFilterValues = useMemo(() => {
    return getShiftPlanningFiltersDefaultValues(appliedFilters, filterData);
  }, [appliedFilters, filterData]);

  const form = useForm<ShiftPlanningFiltersFormType>({ defaultValues: { ...defaultFilterValues } });

  const handleClear = useCallback(() => {
    form.reset({
      fromDate: defaultFilterValues.fromDate,
      fromDateCheckboxValue: false,
      businessUnitCheckboxValue: false,
      company: null,
      branchOffice: null,
      department: null,
      allowApplicantsFilter: false,
      hasApplicants: false,
      filledStatusCheckboxValue: false,
      filledStatus: null,
      partnersCheckboxValue: false,
      partner: null,
      jobTypeCheckboxValue: false,
      jobType: [],
      employmentTypeCheckboxValue: false,
      isFreelance: false,
      isTempWork: false,
    });
  }, [defaultFilterValues, form]);

  const handleCancel = () => {
    toggleFilters();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      form.handleSubmit((data) => {
        const dataToSubmit = {
          fromDate:
            data.fromDateCheckboxValue && data.fromDate
              ? DateTime.fromFormat(data.fromDate, 'yyyy-MM-dd')
              : undefined,
          company: data.businessUnitCheckboxValue ? data.company?.value : undefined,
          branchOffice: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
          hasApplicants:
            data.allowApplicantsFilter && data.hasApplicants !== null
              ? data.hasApplicants
              : undefined,
          filledStatus:
            data.filledStatusCheckboxValue && data.filledStatus !== null
              ? data.filledStatus
              : undefined,
          jobType:
            data.jobTypeCheckboxValue && data.jobType?.length
              ? data.jobType.map((jobTypeSingle) => jobTypeSingle.id)
              : undefined,
          department: data.businessUnitCheckboxValue ? data.department?.value : undefined,
          partner: data.partnersCheckboxValue ? data.partner?.value : undefined,
          isFreelance: data.employmentTypeCheckboxValue ? data.isFreelance : undefined,
          isTempWork: data.employmentTypeCheckboxValue ? data.isTempWork : undefined,
        };

        handleFiltersChange(dataToSubmit);
        toggleFilters();
      })();
    },
    [form, handleFiltersChange, toggleFilters]
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCancel}>
      <FormProvider {...form}>
        <FilterWrapper
          filterName="Filter"
          onCancel={handleCancel}
          onClear={handleClear}
          onSubmit={handleSubmit}
        >
          <FilterFromDate />
          <FilterBusinessUnitsWithCompany companies={filterData?.companies ?? []} />
          {filterData.partners ? <FilterPartners partners={filterData.partners} /> : null}
          <FilterApplicantStatus />
          <FilterFilledStatus />
          <FilterJobTypes jobTypes={filterData.jobTypes} />
          <FilterEmploymentType />
        </FilterWrapper>
      </FormProvider>
    </Drawer>
  );
};
