import { ApplicationDetails, ClaimDetails } from '@types';
import { HistoryEvent } from '../components/shift-detail-modal/components/shift-detail-head/shift-info-button/types';

export type ShiftDetailResponse = {
  id: string; // Shift UUID

  isoEndDateTime: string;
  isoStartDateTime: string;
  startTime: string; // e.g. "16:00"
  endTime: string; // e.g. "18:00"
  startDate: string; // e.g. "2021-03-04"
  endDate: string; // e.g. "2021-03-04"
  mutableUntilDate: string; // e.g. "2021-03-04T14:00:00.000Z"

  workCooldownMinutes: number; // filled with workCooldownMinutes from company
  breakMinutes: number; // filled with breakMinutes from <Job>; can be edited in <Shift>
  location: {
    id: string;
    address: string;
    city: string | null;
    country: string | null;
    houseNumber: string | null;
    postalCode: string | null;
    street: string | null;
    latitude: number | null;
    longitude: number | null;
  };

  maxClaimants: number; // e.g. "5"
  flexPools: { id: string; name: string; directClaimAllowed: boolean }[];
  certificates: string[];

  job: ShiftDetailJob;
  history: HistoryEvent[];

  claimants: ClaimDetails[]; // Empty array when no claimants
  applicants: ApplicationDetails[]; // Empty array when no applicants
  isFreelance: boolean;
  isTempwork: boolean;
  freelanceProperties?: FreelanceProperties;
  tempWorkProperties?: TempWorkProperties;
};

export type FreelanceProperties = {
  hourlyRateCents: number;
  mutableUntilMinutes: number;
};

export type TempWorkProperties = {
  grossHourlyRateCents: number;
};

export type ShiftDetailJob = {
  id: string; // Job UUID
  flexPoolOnly: boolean;
  name: string; // e.g. "Beveiliger",
  companyId: string;
  companyName: string; // e.g. "Felyx"
  location: string; // e.g. "Rotterdam"

  department: {
    id: string; // BranchOffice UUID
    departmentName: string; // e.g. "Felyx Vlaardingen"
  };
};

export const isFreelanceShift = (
  shift: ShiftDetailResponse
): shift is ShiftDetailResponse & { freelanceProperties: FreelanceProperties } =>
  shift.freelanceProperties !== undefined;

export const isTempworkShift = (
  shift: ShiftDetailResponse
): shift is ShiftDetailResponse & { tempWorkProperties: TempWorkProperties } =>
  shift.tempWorkProperties !== undefined;
