import { Box } from '@mui/material';
import { FormCheckbox, LwFormMultiSelect } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { FilterJobTypeFormContext, JobType } from './filter-job-types.types';

type Props = { jobTypes: JobType[] };

export const FilterJobTypes = ({ jobTypes }: Props) => {
  const { watch, control, setValue } = useFormContext<FilterJobTypeFormContext>();
  const { jobTypeCheckboxValue } = watch();

  return (
    <Box>
      <FormCheckbox name="jobTypeCheckboxValue" label="Functiegroep" control={control} />
      {jobTypeCheckboxValue ? (
        <LwFormMultiSelect
          control={control}
          name="jobType"
          ariaLabelInput={'jobType'}
          options={jobTypes}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={(_, selectedOptions) => {
            setValue('jobType', selectedOptions);
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value.id;
          }}
          label="Functiegroep"
        />
      ) : null}
    </Box>
  );
};
