import { Box } from '@mui/material';
import { Header, Page } from 'redesign';
import { CollectiveInvoices } from './collective-invoices';

const Finance = () => {
  return (
    <Page header={<Header titleText={'Verzamelfactuur'} />}>
      <Box width="100%">
        <CollectiveInvoices />
      </Box>
    </Page>
  );
};

export { Finance };
