import { Box, Chip, IconButton, styled, Tooltip } from '@mui/material';
import { IconCross } from 'assets/img';

type FileListProps = {
  files?: File[];
  onClearFiles: () => void;
};

export const UploadedFileList = ({ files = [], onClearFiles }: FileListProps) => {
  return (
    <ListWrapper data-testid="selected-accreditations-list">
      <FilesWrapper>
        {files.map((file) => (
          <StyledTooltip key={file.name} title={file.name.toLowerCase()}>
            <FileChip key={file.name.toLowerCase()} label={file.name.toLowerCase()} />
          </StyledTooltip>
        ))}
      </FilesWrapper>
      {files.length > 0 ? (
        <Box display="flex" flex={1}>
          <IconWrapper onClick={onClearFiles}>
            <IconCross width={20} height={20} />
          </IconWrapper>
        </Box>
      ) : null}
    </ListWrapper>
  );
};

const ListWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '0 8px 8px 8px',
}));

const FilesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflowY: 'hidden',
  overflowX: 'auto',
  maskImage: 'linear-gradient(to right, black 93%, transparent)',
  flex: 20,
  '&::-webkit-scrollbar': {
    height: '18px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
  },
}));

const StyledTooltip = styled(Tooltip)(() => ({
  marginBottom: 0,
}));

const FileChip = styled(Chip)({
  maxWidth: '150px',
});

const IconWrapper = styled(IconButton)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));
