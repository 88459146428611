import { PartnerWorkedShiftsColumnDefinition } from './partner-worked-shifts-table-columns';
import { CheckoutRowItem } from '../../../../shared/services/partner-checkouts-service.types';

export const getPartnerWorkedShiftsTableData = (
  data: CheckoutRowItem[]
): PartnerWorkedShiftsColumnDefinition[] => {
  return data.map((checkout) => {
    return {
      shiftId: checkout.shiftId,
      shiftClaimId: checkout.shiftClaimId,
      flexWorkerName: checkout.flexWorkerName,
      jobName: checkout.jobName,
      companyName: checkout.companyName,
      branchOfficeName: checkout.branchOfficeName,
      workedDate: checkout.workedDate,
      scheduledTime: {
        startDate: checkout.scheduledTime.startDate,
        endDate: checkout.scheduledTime.endDate,
      },
      breakMinutes: checkout.scheduledTime.breakMinutes,
    };
  });
};
