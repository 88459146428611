import { Box, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'redesign';
import { FilterEmploymentTypeFormContext } from './filter-employment-type.types';

export const FilterEmploymentType = () => {
  const { control, watch, clearErrors } = useFormContext<FilterEmploymentTypeFormContext>();
  const { employmentTypeCheckboxValue } = watch();
  const employmentTypes = [
    { name: 'isFreelance' as const, label: 'Zelfstandige' },
    { name: 'isTempWork' as const, label: 'Uitzendkracht' },
  ];
  return (
    <Box>
      <FormCheckbox name="employmentTypeCheckboxValue" label="Dienstverband" control={control} />
      {employmentTypeCheckboxValue ? (
        <StyledBox>
          {employmentTypes.map((item) => (
            <FormCheckbox
              label={item.label}
              key={item.name}
              name={item.name}
              onChange={(val) => {
                if (val) {
                  clearErrors('isFreelance');
                }
              }}
              control={control}
            />
          ))}
        </StyledBox>
      ) : null}
    </Box>
  );
};

const StyledBox = styled(Box)(() => ({
  paddingLeft: '10px',
}));
