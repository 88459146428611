import { partnerSharedService } from 'partner/shared/services';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { useQuery } from 'react-query';
import {
  BusinessUnitFilterParams,
  JobTypeFilterParams,
} from 'partner/shared/services/partner-shared-service.types';

export const useGetFilterBusinessUnitsData = (params: BusinessUnitFilterParams) => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.filterBusinessUnitsData(),
    queryFn: async () =>
      await partnerSharedService.getFilterBusinessUnitsData({
        jobTypeIds: params.jobTypeIds,
      }),
  });
};

export const useGetFilterJobTypesData = (params: JobTypeFilterParams) => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.filterPlanningJobTypesData(),
    queryFn: async () =>
      await partnerSharedService.getFilterJobTypesData({
        companyId: params.companyId,
        branchOfficeId: params.branchOfficeId,
        departmentId: params.departmentId,
      }),
  });
};

export const useGetFilterWorkersData = () => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.filterWorkersData(),
    queryFn: async () => await partnerSharedService.getFilterWorkersData(),
  });
};
