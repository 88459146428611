import { DateTime } from 'luxon';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';

import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { useCallback, useMemo } from 'react';

import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useShiftPlanningPage } from 'hq/components/views/planner/shifts/table/hooks/use-shift-planning';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { transformData } from '../../utils/transform-data';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { HqShiftForm } from '../hq-shift-form';
import { CompanyInput } from '../../components/forms/shared/inputs/company-input';
import { useHqGetShift } from '../../hooks/hq/use-hq-get-shift';
import { useHqDuplicateShift } from '../../hooks/hq/use-hq-duplicate-shift';
import { useHqGeneratePlannerPath } from '../hq-generate-shift-form-path';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';
import { stringifyFilters } from '../../../../utils/stringify-filters';
import { getDefaultValuesForShiftForm } from '../../utils/get-default-values-for-shift-form';
import { HQShiftFormData } from '../types/hq-shift-form.types';

type ShiftDuplicateProps = {
  shift: ShiftDetailResponse;
  companies: GetCompaniesResponse[];
  shiftId: string;
  workDetails: DetailsForWorkTemplate;
};

export const HqShiftDuplicateDataProvider = () => {
  const { id: shiftId } = useParams<'id'>();
  invariant(shiftId, 'Shift ID was supposed to be passed via route param');

  const shiftQuery = useHqGetShift(shiftId);
  const workDetailsQuery = useGetWorkDetails(shiftId);
  const companiesQuery = useGetCompanies();

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return (
    <ShiftDuplicate
      shift={shiftQuery.data}
      shiftId={shiftId}
      companies={companiesQuery.data}
      workDetails={workDetailsQuery.data}
    />
  );
};

const ShiftDuplicate = ({ shift, companies, workDetails }: ShiftDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePlannerPath = useHqGeneratePlannerPath();
  const { filters: appliedFilters } = useShiftPlanningPage();
  const shiftDateTitle = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd').toFormat('dd-LL-yyyy');
  const mergedFilters = useMemo(
    () => ({ ...stringifyFilters(appliedFilters), shiftDetailId: shift.id }),
    [appliedFilters, shift]
  );
  const mutation = useHqDuplicateShift();

  const onCancel = useCallback(
    () => navigate(generatePlannerPath(mergedFilters)),
    [navigate, generatePlannerPath, mergedFilters]
  );

  const formMethods = useForm<HQShiftFormData>({
    defaultValues: { ...getDefaultValuesForShiftForm(shift), jobId: undefined },
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: FreelanceShiftFormData | TempWorkShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate(
        { ...data, ancestorShiftId: shift.id },
        {
          onSuccess: (shiftId) => {
            alertSuccess(alerts.success.created);
            navigate(
              generatePlannerPath({
                ...stringifyFilters(appliedFilters),
                shiftDetailId: shiftId,
              })
            );
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateTitle} dupliceren`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={true}
            ></CompanyInput>
            <HqShiftForm mode="duplicate" workDetails={workDetails} />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
