import { Control } from 'react-hook-form';
import { LwFormDate } from 'redesign';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const StartDateElement = ({
  control,
  minDate,
  disabled,
}: {
  control: Control<ProjectFormTypeAll>;
  minDate: string | undefined;
  disabled: boolean;
}) => {
  return (
    <LwFormDate
      name="startDate"
      label="Startdatum"
      control={control}
      rules={{ required: 'Voer een startdatum in' }}
      minDate={minDate}
      data-testid="start-date"
      disabled={disabled}
    />
  );
};
