import { FilterOptionsState } from '@mui/material';
import { AutocompleteOption } from 'redesign';
import { sanitizeString } from 'shared/utils/utils';

export const getFilterOptions = (
  autocompleteOptions: AutocompleteOption[] | null,
  autocompleteState: FilterOptionsState<any>
) => {
  if (!autocompleteOptions?.length) {
    return [];
  }

  if (autocompleteOptions && autocompleteState.inputValue.length === 0) {
    return autocompleteOptions;
  }

  const sanitizedInputValue = sanitizeString(autocompleteState.inputValue);
  return autocompleteOptions.filter((autocompleteOption) => {
    const sanitizedAutocompleteOption = sanitizeString(autocompleteOption.label);
    return sanitizedAutocompleteOption.includes(sanitizedInputValue);
  });
};
