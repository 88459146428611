import { Interval } from 'luxon';
import { FormCheckbox } from 'redesign';
import { Control } from 'react-hook-form';
import { Box, styled, Typography } from '@mui/material';
import { HighlightBox } from 'ogp/components/views/administration/shifts-checkouts/shared/components/highlight-box';
import { formatCurrencyCents } from 'shared/utils/formatting';
import { formatShiftInterval } from './helpers/helpers';

type BulkAcceptShiftItemProps = {
  name: string;
  shiftInterval: Interval;
  hourlyRateCents: number | null;
  hasDivergentRate: boolean;
  disabled: boolean;
  control: Control<Record<string, boolean>>;
};

export const BulkAcceptShiftItem = ({
  name,
  shiftInterval,
  disabled,
  hourlyRateCents,
  control,
  hasDivergentRate,
}: BulkAcceptShiftItemProps) => {
  return (
    <StyledContainer>
      <FormCheckbox
        disabled={disabled}
        control={control}
        name={name}
        label={formatShiftInterval(shiftInterval)}
      />
      {hourlyRateCents ? (
        hasDivergentRate ? (
          <StyledHighlightBox>{`${formatCurrencyCents(hourlyRateCents)}/ uur`}</StyledHighlightBox>
        ) : (
          <StyledText variant="body2">{`${formatCurrencyCents(hourlyRateCents)}/ uur`}</StyledText>
        )
      ) : null}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(() => ({
  paddingTop: '20px',
  display: 'flex',
  alignContent: 'start',
  alignItems: 'start',
}));

const StyledHighlightBox = styled(HighlightBox)(() => ({
  alignSelf: 'center',
}));

const StyledText = styled(Typography)(() => ({
  alignSelf: 'center',
  padding: '6px 12px',
}));
