import { Certificate } from '@types';

export type AccreditationDataHQ = {
  createdAt: string;
  updatedAt: string;
  expirationDate: string;
  state: string;
  fileUrl: string;
  certificateId: string;
  flexWorkerId: string;
  certificate: Certificate;
};

export type GetAccreditationsResponse = {
  items: RawAccreditationData[];
  totalRows: number;
  nextPage: number;
};

export type RawAccreditationData = {
  createdAt: string;
  startDate: string | null;
  expirationDate: string | null;
  state: AccreditationState;
  type: AccreditationType;
  version: number;
  fileUrl?: string;
  certificateId: string;
  flexWorkerId: string;
  certificate: Certificate;
  flexWorker: {
    id: string;
    imageUrl?: string;
    phone: string;
    email: string;
    firstName: string;
    infix: string;
    lastName: string;
  };
  notification: boolean;
  isThereADocument: boolean;
};

export enum AccreditationType {
  full = 'full',
  receipt = 'receipt',
}

export type AccreditationState = 'rejected' | 'verified' | 'pending' | 'all';

export type HQCertificateWithFileUrl = Certificate & { fileUrl?: string };
